import server from '../utils/Server';
import { mainLoadingFalse, mainLoadingTrue, displayError } from './Home';

export const GET_STREAMS_SUCCESS = 'GET_STREAMS_SUCCESS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';
export const GET_COUNTRY_COLLEGES_SUCCESS = 'GET_COUNTRY_COLLEGES_SUCCESS';
export const GET_BENEFITS_SUCCCESS = 'GET_BENEFITS_SUCCCESS';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';

export const getStreams = () => {
    return dispatch => {
        return server.get('/get_streams')
            .then(response => {
                if(response.data.code === 200)
                {
                    dispatch(onGetStreamsSuccess(response.data.streams));
                }
            })
    }
}

export const onGetStreamsSuccess = (streams) => {
    return {
        type : GET_STREAMS_SUCCESS,
        payload : {
            streams : streams
        }
    }
}

export const getCourseLevels = () => {
    return dispatch => {
        return server.get('/get_course_levels')
            .then(response => {
                if(response.data.code === 200)
                {
                    dispatch(onGetCourseLevelsSuccess(response.data.course_levels));
                }
            })
    }
}

export const onGetCourseLevelsSuccess = (course_levels) => {
    return {
        type : GET_LEVELS_SUCCESS,
        payload : {
            course_levels : course_levels
        }
    }
}
export const getCountryColleges = (country_id) => {
    return dispatch => {
        return server.get('/get_country_colleges/' + country_id)
            .then(response => {
                if(response.data.code === 200)
                {
                    dispatch(onGetCountryColleges(response.data.country_colleges));
                }
            })
    }
}

export const onGetCountryColleges = (country_colleges) => {
    return {
        type : GET_COUNTRY_COLLEGES_SUCCESS,
        payload : {
            country_colleges : country_colleges
        }
    }
}

export const getPlanBenefits = () => {
    return dispatch => {
        return server.get('/get_plan_benefits')
            .then(response => {
                if(response.data.code === 200)
                {
                    dispatch(onGetBenefitsSuccess(response.data.benefits));
                }
            })
    }
}

export const onGetBenefitsSuccess = (benefits) => {
    return {
        type : GET_BENEFITS_SUCCCESS,
        payload : {
            benefits : benefits
        }
    }
}

export const getMembershipPlans = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());

        return server.get('/get_membership_plans')
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(onGetPlansSuccess(response.data.member_plans));
                }
                else
                {
                    dispatch(displayError('Failed, try again later'));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            })
    }
}

export const onGetPlansSuccess = (member_plans) => {
    return {
        type : GET_PLANS_SUCCESS,
        payload : {
            member_plans : member_plans
        }
    }
}


export const getPlanDetails = (plan_name) => {
    return dispatch => {
        dispatch(mainLoadingTrue());

        return server.get('/get_plan_details/' + plan_name)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(onGetPlanDetailsSuccess(response.data.member_plan));
                }
                else
                {
                    dispatch(displayError('Failed, try again later'));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Failed, try again later'));
            })
    }
}

export const onGetPlanDetailsSuccess = (member_plan) => {
    return {
        type : GET_PLAN_SUCCESS,
        payload : {
            member_plan : member_plan
        }
    }
}