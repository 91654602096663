import server from '../utils/Server';
import { mainLoadingTrue, mainLoadingFalse, displayError } from './Home';
import swal from 'sweetalert';

export const SET_CALLBACK_VALUES = 'SET_CALLBACK_VALUES';

export const requestCallback = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/request_callback', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    swal({ title :'Thank you', text : response.data.message, icon : 'success'});
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }

            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                dispatch(displayError('Request failed, Try again later'));
            })
    }
}

export const setCallbackValues = (country, college, stream, course_level) => {
    return {
        type : SET_CALLBACK_VALUES,
        payload : {
            country : country,
            college : college,
            stream : stream, 
            course_level : course_level
        }
    }
}
