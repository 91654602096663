import React from 'react';
import './Routes.css';
import { NavLink, Link, HashRouter as Router, Route } from 'react-router-dom';
import Home from '../Home';
import AboutUs from '../AboutUs';
import Pricing from '../Pricing';
import Checkout from '../Checkout';
import PaymentSuccess from '../Checkout/PaymentSuccess';
import Register from '../Register';
import Contact from '../Contact';
import CountryFilters from '../CountryFilters';
import CollegeFilters from '../CollegeFilters';
import CollegeDetails from '../CollegeDetails';
import Autocomplete from '../Autocomplete/HeaderSearchAutocomplete';
import TermsConditions from '../TermsConditions';
import PrivacyPolicy from '../PrivacyPolicy';
import CollegeCompare from '../CollegeCompare';
import EditProfile from '../EditProfile';
import ChangePassword from '../EditProfile/ChangePassword';
import { connect } from 'react-redux';
import $ from 'jquery';

class Routes extends React.Component {

	constructor (props) {
		super (props);
		this.handleCountryNavigate = this.handleCountryNavigate.bind(this);
		this.handleCountriesDropDown = this.handleCountriesDropDown.bind(this);
	}

	handleCountryNavigate = (country_id) => {
		$('.countries_dropdown_content').addClass('d-none');
		window.location.href = '/#/college_filters?c_country=' + country_id;
	}

	handleCountriesDropDown = (id) => {
		if(id === 1)
			$('.countries_dropdown_content').removeClass('d-none');
		else if(id === 2)
			$('.countries_dropdown_content').addClass('d-none');
	}

	render() {

		const { autocomplete_colleges, is_auth, user_info, college_countries } = this.props;

		return (
			
			<div className="row">
				<div className="col">
				
					<Router>
						
						<nav className="navbar navbar-expand-md" id="home_navbar">
							<button className="navbar_btn navbar-toggler" type="button" data-toggle="collapse" data-target="#main_header_navbar" aria-controls="main_header_navbar" aria-expanded="false" aria-label="{{ __('Toggle navigation') }}">
								<img src={process.env.PUBLIC_URL + '/menu_button.png'} alt="Menu" height="30"/>
							</button>
							
							<Link to="/" className="navbar-brand">
								<img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" height="33"/>
							</Link>

							<form autoComplete="off" className="form-inline header_search">
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"><i className="fa fa-search"></i> </span>
									</div>
									
									<Autocomplete
										suggestions={ autocomplete_colleges && autocomplete_colleges.length > 0 ? autocomplete_colleges : []}
									/>
								</div>
							</form>

							<div className="collapse navbar-collapse" id="main_header_navbar">
								<ul className="navbar-nav ml-auto sticky">
									<li> <NavLink className="nav-link" activeClassName="link_active" exact to="/"> Home </NavLink> </li>
									<li> <NavLink className="nav-link" activeClassName="link_active" exact to="/about_us"> About Us </NavLink> </li>

									{/* Countries dropdown if countries exists */}
									<li> 
										<div className="countries_dropdown" onMouseEnter={() => this.handleCountriesDropDown(1)} onMouseLeave={() => this.handleCountriesDropDown(2)}>
											<NavLink className="nav-link" activeClassName="link_active" exact to="/country_filters"> Countries <i className="fa fa-caret-down"></i> </NavLink>
											
											<div className="countries_dropdown_content d-none animated fadeIn">
												<div className="row">
												{
													college_countries && college_countries.length > 0 ? college_countries.map((country) => {
														return <div key={country.id} className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
															<span className="country_link" onClick={() => this.handleCountryNavigate(country.id)}><i className="fa fa-globe mr-1"></i> {country.con_name} </span>
														</div>
													}) : ''
												}
												</div>
											</div>
										</div>
									
									</li>

								
									<li> <NavLink className="nav-link" activeClassName="link_active" exact to="/college_filters"> Colleges </NavLink> </li>
									<li> <NavLink className="nav-link" activeClassName="link_active" exact to="/college_compare"> Compare </NavLink> </li>
									<li> <NavLink className="nav-link" activeClassName="link_active" exact to="/pricing"> Pricing </NavLink> </li>
									<li> <NavLink className="nav-link" activeClassName="link_active" exact to="/contact"> Contact </NavLink> </li>
									{/* <li> <NavLink className="nav-link" activeClassName="link_active" exact to="/payment_success"> Thanks </NavLink> </li> */}
									{
										is_auth && user_info ? 
											<li><span className="nav-link show_profile"> <i className="fa fa-user"></i> {user_info.fname} </span></li>
										: <li> <button className="abroad_btn no_shadow show_login"> <i className="fa fa-sign-in"></i> Login</button> </li>
									}
									
								</ul>
							</div>
						</nav>

						<Route exact path="/" component={Home} />
						<Route exact path="/about_us" component={AboutUs} />
						<Route exact path="/pricing" component={Pricing} />
						<Route exact path="/checkout/:plan_name" component={Checkout} />
						<Route exact path="/register" component={Register} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/country_filters" component={CountryFilters} />
						<Route exact path="/college_filters" component={CollegeFilters} />
						<Route exact path="/college_details/:college" component={CollegeDetails} />
						<Route exact path="/terms_conditions" component={TermsConditions} />
						<Route exact path="/privacy_policy" component={PrivacyPolicy} />
						<Route exact path="/college_compare" component={CollegeCompare} />

						{
							is_auth && user_info ? 
								<div>
									<Route exact path="/edit_profile" component={EditProfile} />
									<Route exact path="/change_password" component={ChangePassword} />
									<Route exact path="/payment_success/:plan_name" component={PaymentSuccess} />
								</div>
							: ''
						}
					</Router>
				</div>
			</div>

		)
	}
}

const mapStatesToProps = (state) => {
	return {
		autocomplete_colleges : state.home.autocomplete_colleges,
		is_auth : state.home.is_auth,
		user_info : state.home.user_info,
		college_countries : state.country.college_countries
	}
}

export default connect(mapStatesToProps) (Routes);
