import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'

import Home_reducer from '../reducers/Home';
import College_reducer from '../reducers/Colleges';
import Country_reducer from '../reducers/Countries';
import Listing_reducer from '../reducers/Listings';
import Callback_reducer from '../reducers/Callbacks';

const All_reducers = combineReducers({
    home : Home_reducer,
    college : College_reducer,
    country : Country_reducer,
    list : Listing_reducer,
    callback : Callback_reducer
});

const store = createStore(All_reducers, applyMiddleware(thunk));

export default store;