import { GET_COUNTRIES_SUCCESS, GET_COLLEGE_COUNTRIES } from '../actions/Countries';

const initialState = {
    countries : [],
    college_countries : [],
    page_size : 10,
    page_count : 0,
    total_count :  0,
}

const Country_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_COUNTRIES_SUCCESS :
            return Object.assign({}, state, { countries : payload.countries, page_size : payload.page_size, page_count : payload.page_count, total_count : payload.total_count });

        case GET_COLLEGE_COUNTRIES :
            return Object.assign({}, state, { college_countries : payload.college_countries });

        default :
            return state;
    }
}

export default Country_reducer;