import React from 'react';
import './Footer.css';
import Logo from '../../assets/logo.png';
import { HashRouter as Router, NavLink, Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

class Footer extends React.Component {

	render () {

		return (

			// <ScrollAnimation animateIn="fadeIn" duration={0.5}>
				<div className="row footer_container">
					<div className="col text-center">
						<div className="row">
							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center">
							
								<img src={Logo} height="66" alt="Logo"/>
								<h6 className="mt-3"> GOOCAMPUS EDU SOLUTIONS PVT LTD </h6>
								<p className="mt-3"> <i className="fa fa-map-marker mr-1"></i> # 138/6, Ground Floor, 10th Main Road, 6th A Cross, Sadashiv Nagar, RMV Extension, Behind SBI Bank,
									Bengaluru, Karnataka - 560080 <br/>
									Landmark : Next to Chevron Orchards
                				</p>
							</div>

							<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 connect_with text-center">
								<h6><b> CONNECT WITH US </b></h6>
						
								<p className="mt-4"> <i className="fa fa-phone mr-1"></i> 080-41743956 </p>
								<p className="mt-2"> <i className="fa fa-envelope mr-1"></i> info@goocampus.in </p>
								<br/>
								<ul className="social text-center">
									<li> <a target="_blank" rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/goocampus/"><i className="social_icon fa fa-facebook"></i></a> </li>
									<li> <a target="_blank" rel="noopener noreferrer" title="LinkedIn" href="https://www.linkedin.com/company/gocampus-in/"><i className="social_icon fa fa-linkedin"></i></a> </li>
									<li> <a target="_blank" rel="noopener noreferrer" title="Instagram" href="https://www.instagram.com/goocampus/"><i className="social_icon fa fa-instagram"></i></a> </li>
									<li> <a target="_blank" rel="noopener noreferrer" title="Youtube" href="https://www.youtube.com/channel/UCmo54Vb1QG6YoBplgmIh0TQ"><i className="social_icon fa fa-youtube"></i></a> </li>
									<li> <a target="_blank" rel="noopener noreferrer" title="Twitter" href="https://twitter.com/@goocampus_in"><i className="social_icon fa fa-twitter"></i></a> </li>
								</ul>
							</div>

							<div className="col"></div>
							<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
								
								<h6><b> NAVIGATE </b></h6>

								<Router>
									<ul className="navigate text-left mt-3">
										<li> <NavLink exact to="/about_us" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> About Us </NavLink> </li>
										<li> <NavLink exact to="/contact" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> Contact </NavLink> </li>
										<li> <NavLink exact to="/country_filters" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> Countries </NavLink> </li>
										<li> <NavLink exact to="/college_filters" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> Colleges </NavLink> </li>
										<li> <NavLink exact to="/pricing" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> Pricing </NavLink> </li>
										<li> <NavLink exact to="/terms_conditions" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> Terms & conditions </NavLink> </li>
										<li> <NavLink exact to="/privacy_policy" activeClassName="link_active"> <i className="fa fa-chevron-right mr-1"></i> Privacy policy </NavLink> </li>
									</ul>
								</Router>
							</div>
						</div>

						<hr />

						{/* <h6><b> INDIAN BRANCHES </b></h6>

						<Router>
							<ul className="branches mt-3 text-center">
								<li> <Link to="/contact"><i className="fa fa-globe mr-1"></i> Trivandrum, Kerala </Link></li>
								<li> <Link to="/contact"><i className="fa fa-globe mr-1"></i> Guwahati, Assam </Link></li>
								<li> <Link to="/contact"><i className="fa fa-globe mr-1"></i> Guntur, Andra Pradesh </Link></li>
								<li> <Link to="/contact"><i className="fa fa-globe mr-1"></i> Visakhapatnam, Andra Pradesh </Link></li>
								<li> <Link to="/contact"><i className="fa fa-globe mr-1"></i> Vizianagaram, Andra Pradesh </Link></li>
							</ul>
						</Router>

						<hr/> */}

						<p className="copyright"> copyright &copy; <span> GooCampus Edu Solutions Pvt Ltd. </span> @ 2020 </p>
					</div>
				</div>
			// </ScrollAnimation>

		)
	}
}

export default Footer;