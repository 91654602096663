import axios from 'axios';

const server = axios.create({
    // baseURL : 'http://localhost:8000/api/user',
    baseURL : 'https://www.goocampusabroad.com/abroad/api/user',
    headers : { 
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded',
    }
});

export default server; 