import React from 'react';
import './Pricing.css';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { setCallbackValues } from '../../actions/Callbacks';
import { getPlanBenefits, getMembershipPlans } from '../../actions/Listings';
import PopularIcon from '../../assets/popular.png';
import SaleIcon from '../../assets/sale.png';

class Pricing extends React.Component {

  constructor (props) {
    super (props);
    this.handleCallback = this.handleCallback.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  componentWillMount = () => {
    this.props.onGetPlanBenefits();
    this.props.onGetMemberPlans();
  }

  handleCallback = (country, college, stream, level) => {
		// Show side callback panel
		$('.callback_container').removeClass('d-none').removeClass('slideOutLeft').addClass('slideInLeft');

		this.props.onSetCallbackValues(country, college, stream, level);
	}

  handleLoginClick = (e) => {
    // Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
  }

  render () {

    const { is_auth, benefits, member_plans } = this.props;

    return (

      <div className="row mt-4 pt-4">
        <div className="col">
          
          <div className="container pricing_container text-center">

            <h4 className="header"> Choose your best plan.</h4>
            <span className="small_text"> Prices are in Indian Rupees - INR, Inclusive of all taxes. </span>
            <br/>

            {/* membership plans display */}
            <div className="row justify-content-center pt-4">
              {
                member_plans && member_plans.length > 0 ?

                  member_plans.map((plan) => (

                    <div key={plan.id} className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-3">
                      <div className="pricing_package">
                        <div className="package_header">
                          <h5 className="text-uppercase"> {plan.plan_name} </h5>
                          {
                            plan.offer_price > 0 && plan.offer_price < plan.plan_price ?
                              <div>
                                <span className="actual_price"> <i className="fa fa-rupee"></i> {plan.plan_price} </span><br/>
                                <h1> <i className="fa fa-rupee"></i> {plan.offer_price} <img src={SaleIcon} alt="Offer" className="offer_banner" /> </h1>
                                {/* {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} {Math.round((plan.plan_price - plan.offer_price) / plan.plan_price * 100 )}% off. </strong></span> : ''} */}
                                {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} </strong></span> : ''}
                              </div>
                            : <h1> <i className="fa fa-rupee"></i> {plan.plan_price} </h1>
                          }

                          {/* Display popular banner */}
                          {plan.is_popular === '1' ? <img src={PopularIcon} alt="Popular" className="popular_banner" /> : ''}
                        </div>
      
                        <div className="package_body">
                          {
                            benefits && benefits.length > 0 ?
                              benefits.map((benefit) => (
                                plan.plan_benefits && plan.plan_benefits.includes(benefit.id) ? 
                                  <p className="benefit_true" key={benefit.id}><i className="fa fa-check mr-1"></i> {benefit.benefit} </p>
                                : <p className="benefit_false" key={benefit.id}><i className="fa fa-ban mr-1"></i> {benefit.benefit} </p>
                              ))
                            : ''
                          }      
                          <div className="text-center">
                            <Link to={'/checkout/' + plan.plan_name}><button className="abroad_btn mt-3"> <i className="fa fa-check mr-1"></i> CHECKOUT </button></Link>
                          </div>
                        </div>
      
                      </div>
                    </div>

                  ))
                : ''
              }
            </div>
          </div>

          {/* get in touch container */}
          <div className="row interested_container">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
              <h2 className="main_header"><b> Interested? </b></h2>
              <h5 className="text-center mt-3"> Request a callback {is_auth ? '' : 'or Sign In' } to get in touch with us to know better about the colleges, study and work in abroad.</h5>
              <div className="mt-3">
                {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2 show_login"> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>}
                <button className="mt-2 abroad_btn py-2" onClick={() => this.handleCallback('', '','','')}> <i className="fa fa-phone mr-2"></i> REQUEST CALLBACK</button>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    )
  }
}

const mapStatesToProps = (state) => {
  return {
    is_auth : state.home.is_auth,
    benefits : state.list.benefits,
    member_plans : state.list.member_plans
  }
}

const mapActionsToProps = {
  onGetPlanBenefits :getPlanBenefits,
  onGetMemberPlans : getMembershipPlans,
  onSetCallbackValues : setCallbackValues
}

export default connect(mapStatesToProps, mapActionsToProps) (Pricing);