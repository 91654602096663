import React from 'react';
import './Contact.css';
import Callback from '../Callback';
import VisitIcon from '../../assets/visit_us.png';
import ContactIcon from '../../assets/contact_us.png';
import FollowIcon from '../../assets/follow_us.png';

class Contact extends React.Component {

  render() {

    return (

      <div className="row mt-4 pt-5 mb-3" id="contact_container">
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
            
            {/* Callback form */}
            <div className="contact_form">
              <Callback />
            </div>
            <br/>
        </div>

        <div className="col text-center mt-2">
          <div className="my-4">
            <h3 className="main_header"> GOOCAMPUS EDU SOLUTIONS PVT LTD </h3>
            <h6 className="header"> Bengaluru, Karnataka, India </h6>
          </div>
          <hr/>
          
          <div className="row">
            {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"> */}
            <div className="col"></div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">

              <div>
                <img src={VisitIcon} alt="Visit Us" />
                <h1 className="sub_header"> VISIT US</h1>
                <p className="para"> <i className="fa fa-map-marker mr-1"></i> # 138/6, Ground Floor, 10th Main Road, 6th A Cross, Sadashiv Nagar, RMV Extension, Behind SBI Bank,
									Bengaluru, Karnataka - 560080 <br/>
									Landmark : Next to Chevron Orchards
                </p>
              </div>
            </div>
            <div className="col"></div>
          </div>
          <hr/>

          <div className="row mt-5">
            <div className="col">
              <div>
                <img src={ContactIcon} alt="Contact Us" />
                <h1 className="sub_header"> CONTACT US</h1>
                <p className="para my-1" style={{fontSize: 16}}> <i className="fa fa-phone mr-1"></i> : 080 41743956 </p>
                <p className="para my-1" style={{fontSize: 16}}><i className="fa fa-envelope mr-1"></i> : info@goocampus.in </p>
              </div>
            </div>

            <div className="col">
              <div>
                <img src={FollowIcon} alt="Follow Us" />
                <h1 className="sub_header"> FOLLOW US</h1>
                <ul className="social text-center">
                  <li> <a target="_blank" rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/goocampus/"><i className="social_icon fa fa-facebook"></i></a> </li>
                  <li> <a target="_blank" rel="noopener noreferrer" title="LinkedIn" href="https://www.linkedin.com/company/gocampus-in/"><i className="social_icon fa fa-linkedin"></i></a> </li>
                  <li> <a target="_blank" rel="noopener noreferrer" title="Instagram" href="https://www.instagram.com/goocampus/"><i className="social_icon fa fa-instagram"></i></a> </li>
                  <li> <a target="_blank" rel="noopener noreferrer" title="Youtube" href="https://www.youtube.com/channel/UCmo54Vb1QG6YoBplgmIh0TQ"><i className="social_icon fa fa-youtube"></i></a> </li>
                  <li> <a target="_blank" rel="noopener noreferrer" title="Twitter" href="https://twitter.com/@goocampus_in"><i className="social_icon fa fa-twitter"></i></a> </li>
                </ul>
              </div>
            </div>
          </div>
          <hr/>

          {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12"> */}
          <div className="row justify-content-center d-none" id="branch_offices">
            <div className="col-md-12 col-12">
              <h5 className="header"> We are located accross india </h5>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 branch_div">
              <h5 className="sub_header"> Trivandrum, Kerala </h5>
              <h6 className="header"> Mr. Rajesh - AIKYA </h6>
              <p className="para"> <i className="fa fa-map-marker mr-1"></i> Menamthottam Chambers, First Floor, Opposite Pattom Royal Hotel, Pattom, Trivandrum, Kerala, India - 695004 <br/>
                <strong><i className="fa fa-phone"></i> : 0471-4000630, 4000830, 9567556111 </strong>
              </p>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 branch_div">
              <h5 className="sub_header"> Guwahati, Assam </h5>
              <h6 className="header"> Mr. Arshad, Mr. Hemant </h6>
              <p className="para"> <i className="fa fa-map-marker mr-1"></i> No.21, SixMile, Panjabari Road, P.S. Dispur, P.O. Khanapara, Guwahati, Assam, India - 781022 <br/> Landmark : Near Darandha Maszid <br/>
                <strong><i className="fa fa-phone"></i> : 0361-2332287, 7483800703 / 04 </strong>
              </p>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 branch_div">
              <h5 className="sub_header"> Secunderabad, Telangana </h5>
              <h6 className="header"> Mr. John </h6>
              <p className="para"> <i className="fa fa-map-marker mr-1"></i> Plot No.112, First Floor, Road No. 2, TMC Colony, Mahendra Hills, East Marredpally, Secundarabad, Telangana, India - 500026 <br/>
                {/* <strong><i className="fa fa-phone"></i> : 0361-2332287, 7483800703 / 04 </strong> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact;
