import server from '../utils/Server';
import SetAuthToken from '../utils/SetAuthToken';
import $ from 'jquery';

export const GET_AUTO_COLLEGES_SUCCESS = 'GET_AUTO_COLLEGES_SUCCESS';
export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';
export const MAIN_LOAD_TRUE = 'MAIN_LOAD_TRUE';
export const MAIN_LOAD_FALSE = 'MAIN_LOAD_FALSE';
export const SUB_LOAD_TRUE = 'SUB_LOAD_TRUE';
export const SUB_LOAD_FALSE = 'SUB_LOAD_FALSE';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';

export const getAutocompleteColleges = (country_id) => {
    return dispatch => {
        return server.get('/get_autocomplete_colleges' + country_id)
            .then(response => {
                if(response.data.code === 200)
                {
                    dispatch(getAutocompleteCollegesSuccess(response.data.colleges));
                }
            })
    }
}

export const getHomeDetails = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.get('/get_home_details')
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(getHomeSuccess(response.data));
                }
            })
    }
}

export const registerUser = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/register', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(displayMessage(response.data.message));
                    localStorage.setItem('user_info', JSON.stringify(response.data.user_info));
                    var token = response.data.auth_token;
                    localStorage.setItem('auth_token', token);
                    SetAuthToken(token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(response.data.user_info));
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const userLogin = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/login', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(displayMessage(response.data.message));
                    localStorage.setItem('user_info', JSON.stringify(response.data.user_info));
                    var token = response.data.auth_token;
                    localStorage.setItem('auth_token', token);
                    SetAuthToken(token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(response.data.user_info));
                    window.location.reload();
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const userLogout = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/logout')
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('user_info');
                    SetAuthToken('');
                    dispatch(userLogoutSuccess());
                }
            })
    }
}

export const addRemoveFavourite = (college_id) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/add_remove_favourite_college/' + college_id)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(displayMessage(response.data.message));
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const updateProfile = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/update_profile', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(displayMessage(response.data.message));
                    localStorage.setItem('user_info', JSON.stringify(response.data.user_info));
                    dispatch(setAuthUser(response.data.user_info));
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const updatePassword = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/update_password', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(displayMessage(response.data.message));
                    $('#edit_pwd, #edit_new_pwd, #edit_cfrm_pwd').val('');
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const getAutocompleteCollegesSuccess = (colleges) => {
    return {
        type : GET_AUTO_COLLEGES_SUCCESS,
        payload : {
            autocomplete_colleges : colleges
        }
    }
}

export const getHomeSuccess = (data) => {
    return {
        type : GET_HOME_SUCCESS,
        payload : {
            all_popular_countries : data.all_popular_countries,
            popular_countries : data.popular_countries,
            popular_colleges : data.popular_colleges,
            favourite_colleges : data.favourite_colleges
        }
    }
}

export const mainLoadingTrue = () => {
    return {
        type : MAIN_LOAD_TRUE
    }
}

export const mainLoadingFalse = () => {
    return {
        type : MAIN_LOAD_FALSE
    }
}

export const subLoaderTrue = () => {
    return {
        type : SUB_LOAD_TRUE
    }
}

export const subLoaderFalse = () => {
    return {
        type : SUB_LOAD_FALSE
    }
}

export const displayMessage = (message) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            message : message
        }
    }
}

export const displayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : {
            error : error
        }
    }
}

export const hideMessage = () => {
    return {
        type : HIDE_MESSAGE,
    }
}

export const setAuthUser = (user_info) => {
    return {
        type : SET_AUTH_USER,
        payload : {
            user_info : user_info
        }
    }
}

export const loginSuccess = () => {
    return {
        type : LOGIN_SUCCESS
    }
}

export const userLogoutSuccess = () => {
    return {
        type : LOGOUT_SUCCESS
    }
}

export const saveCheckoutSuccess = (order_id) => {
    return {
        type : CHECKOUT_SUCCESS,
        payload : {
            order_id : order_id
        }
    }
}