import { GET_STREAMS_SUCCESS, GET_LEVELS_SUCCESS, GET_COUNTRY_COLLEGES_SUCCESS, GET_BENEFITS_SUCCCESS, GET_PLANS_SUCCESS, GET_PLAN_SUCCESS } from '../actions/Listings';

const initialState = {
    streams : [],
    course_levels : [],
    country_colleges : [],
    benefits : [],
    member_plans : [],
    member_plan : {}
}

const Listing_reducer = (state = initialState, { type, payload }) => {

    switch(type)
    {
        case GET_STREAMS_SUCCESS :
            return Object.assign({}, state, { streams : payload.streams });

        case GET_LEVELS_SUCCESS :
            return Object.assign({}, state, { course_levels : payload.course_levels });

        case GET_COUNTRY_COLLEGES_SUCCESS :
            return Object.assign({}, state, { country_colleges : payload.country_colleges });

        case GET_BENEFITS_SUCCCESS :
            return Object.assign({}, state, { benefits : payload.benefits });

        case GET_PLANS_SUCCESS :
            return Object.assign({}, state, { member_plans : payload.member_plans });

        case GET_PLAN_SUCCESS :
            return Object.assign({}, state, { member_plan : payload.member_plan });

        default :
            return state;
    }
}

export default Listing_reducer;