import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import Autocomplete from '../Autocomplete/HomeSearchAutocomplete';
import { connect } from 'react-redux';
import { getAutocompleteColleges, getHomeDetails, addRemoveFavourite } from '../../actions/Home';
import $ from 'jquery';
import { setCallbackValues } from '../../actions/Callbacks';
import ScrollAnimation from 'react-animate-on-scroll';
import SearchImage from '../../assets/search.png';
import CollegesIcon from '../../assets/colleges.png';
import CountriesIcon from '../../assets/countries2.png';
import JoinIcon from '../../assets/join_us.png';
import ComapareIcon from '../../assets/compare.png';
import FavouriteIcon from '../../assets/favorite.png';

class Home extends React.Component {

	constructor (props) {
		super(props);
		this.state = { callback_college : '', callback_country : '', popular_countries_display : '' };
		this.changeCountryHandler = this.changeCountryHandler.bind(this);
		this.handleCallback = this.handleCallback.bind(this);
		this.displayCountry = this.displayCountry.bind(this);
	}

	componentWillMount = () => {
		this.props.onGetHomeDetails();
	}

	componentDidMount = () => {
		setInterval(this.displayCountry, 3500);
	}

	displayCountry = () => {
		let countries = this.props.all_popular_countries;

		if(countries && countries.length > 0)
		{
			// Fetching random countries to display
			let randIndex = Math.floor(Math.random() * countries.length);
			let randomCountry = countries[randIndex];

			$('.search_country_display').addClass('fadeOut');

			setTimeout( function () { $('.search_country_display').html(randomCountry).removeClass('fadeOut'); }, 1500);
		}
	}

	changeCountryHandler = (e) => {
		if(e.target.name === 'c_country')
		{
			this.props.onGetAutocompleteColleges('?country_id=' + e.target.value);
		}
	}

	submitSearchHandler = (e) => {
		var params = $('#college_search_form').serialize();
		this.props.history.push({
			pathname: '/college_filters',
			search: '?' + params,
		  })
	}

	handleCallback = (country, college, stream, course_level) => {
		// Show side callback panel
		$('.callback_container').removeClass('d-none').removeClass('slideOutLeft').addClass('slideInLeft');

		this.props.onSetCallbackValues(country, college, stream, course_level);
	}

	handleFavouriteCollege = (college_id) => {
		if(this.props.is_auth && this.props.user_info)
		{
			this.props.onAddRemoveFavourite(college_id);
			$('#fav_icon_' + college_id).toggleClass('fa-heart-o').toggleClass('fa-heart');
		}
		else
		{
			// Show login
			$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
		}
	}

	render() {

		const { autocomplete_colleges, college_countries, popular_countries, popular_colleges, favourite_colleges, is_auth, user_info } = this.props;

		return (

			<div className="home_container" id="home_container">
				<div className="row home_background p-4">
					{/* <div className="col"></div> */}
					<div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 p-4 text-center mt-3">
						{/* <img src={process.env.PUBLIC_URL + '/home_bg_5.jpg'} alt="Banner" className="img-fluid home_bg_img"/> */}

						<div className="home_banner_content pt-5">
							
							<h3 className="search_header mt-3"> Search medical colleges in </h3>

							<h1 className="search_country_display text-orange animated fadeIn slow mt-3"> India </h1>

							<h5 className="small_text text-blue mt-4"> and other <Link to="/country_filters"><span className="text-blue"> {college_countries.length - 1} countries </span></Link> worldwide.</h5>

							{/* Display all countries which have colleges */}
							<form id="college_search_form" autoComplete="off">  

								{/* <div className="mt-4">
									<select name="c_country" className="home_country_select" onChange={this.changeCountryHandler} >
										<option value="">Select country</option>
										{
											college_countries && college_countries.length > 0 ? college_countries.map((country) => {
												return <option key={country.id} value={country.id}> {country.con_name}</option>
											}) : ''
										}
									</select>
								</div> */}

								<div className="home_search_group mt-4">
									<div className="input-group">

										<Autocomplete
											suggestions={ autocomplete_colleges && autocomplete_colleges.length > 0 ? autocomplete_colleges : [] }
										/>

										<div className="input-group-append">
											<button type="button" className="input-group-text" onClick={this.submitSearchHandler}><i className="fa fa-search mr-1"></i></button>
										</div>
									</div>
								</div>
							</form>

							{/* <a href="#down_arrow"><h4 id="down_arrow" className="text-center mt-5 down_arrow"><i className="fa fa-arrow-down"></i></h4></a> */}
						</div>
					</div>
					<div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 px-5 py-3">
						<div className="p-4">
							<img src={SearchImage} className="img-fluid" alt="Search Colleges" />
						</div>
					</div>
				</div>

				<div className="row explore_container">
					<div className="col"></div>
					<div className="col-xl-10 col-lg-10 col-md-10 col-sm-11 col-12 p-4 text-center home_service_container">
						
						<h3 className="header"> Explore across the world. </h3>

						{/* <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}> */}
							<div className="row mt-4">
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-2">
									<Link to="/country_filters">
										<div className="card">
											<div className="card-body">
												<img src={CountriesIcon} alt="Icon" height="66"/>
												{/* <h1 className="mt-2"> 123 </h1> */}
												<h6 className="mt-3"><b> COUNTRIES </b></h6>
												<p> Explore all countries which have colleges of your interests acrooss the world. </p>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-2">
									<Link to="/college_filters">
										<div className="card">
											<div className="card-body">
												<img src={CollegesIcon} alt="Icon" height="66"/>
												{/* <h1 className="mt-2"> 897 </h1> */}
												<h6 className="mt-3"><b> COLLEGES </b></h6>
												<p> Explore all colleges you are interested to join acrooss the world. </p>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-2">
									<Link to="/college_compare">
										<div className="card">
											<div className="card-body">
												<img src={ComapareIcon} alt="Icon" height="66"/>
												{/* <h1 className="mt-2"> College </h1> */}
												<h6 className="mt-3"><b> COLLEGE COMPARE </b></h6>
												<p> Compare any of colleges around the world to know more about colleges. </p> 
											</div>
										</div>
									</Link>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 my-2">
									{
										is_auth && user_info ? 
											<Link to={{ pathname : "/college_filters", search : 'c_favourite=1' }}>
												<div className="card">
													<div className="card-body">
														<img src={FavouriteIcon} alt="Icon" height="66"/>
														{/* <h1 className="mt-2"> 2389 </h1> */}
														<h6 className="mt-3"><b> FAVOURITES </b></h6>
														<p> Explore your favourite colleges you have favourited around the world. </p>
													</div>
												</div>
											</Link>
										:
											<div className="card show_login">
												<div className="card-body">
													<img src={JoinIcon} alt="Icon" height="66"/>
													{/* <h1 className="mt-2"> 2389 </h1> */}
													<h6 className="mt-3"><b> JOIN US </b></h6>
													<p> Join us / Login to get know more about and Get in touch with us.  </p>
												</div>
											</div>
									}
								</div>

							</div>
						{/* </ScrollAnimation> */}

					</div>
					<div className="col"></div>
				</div>

				{/* Display Popular countries container */}
				{
					popular_countries && popular_countries.length > 0 ?

						// <ScrollAnimation animateIn="fadeIn" delay={100} duration={1}>
							<div className="row p-4 popular_section" id="popular_countries_section">
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center">
									<h2 className="main_header"><b> Popular countries </b></h2>
									<hr className="hori_line"/>

									<h6 className="sub_header"> Most top rated college countries</h6>

									<div className="row p-2">
										{
											popular_countries.map((country) => (
												
												<div key={country.id} className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-4">
													<div className="card">
														<div className="col p-0">
															<img src={country.con_banner && country.con_banner !== '' ? country.con_banner : process.env.PUBLIC_URL + '/default_country_banner.png'} alt="Banner" height="222" width="100%"/>
															<div className="bg_overlay"></div>
															
															<div className="mci_fmge_group">
																{ country.fmge_percentage ? <button className="fmge_div"> FMGE : {country.fmge_percentage} % </button> : ''}
															</div>

															<div className="col_details">
																<h2 className="col_name"> {country.con_name} </h2>
															</div>
														</div>
						
														<div className="card-footer">
															<div className="button-group">
																<button className="abroad_btn mr-2" onClick={() => this.handleCallback(country.id, '', '', '')}> <i className="fa fa-phone"></i> Callback </button>
																<Link to={{ pathname : '/college_filters', search : '?c_popular=1&c_country=' + country.id}}><button className="ml-2 abroad_btn"> <i className="fa fa-eye"></i> View colleges </button></Link>
															</div>
														</div>
													</div>
												</div>
											))
										}
									</div>

									<Link to="country_filters"><button className="ml-2 abroad_btn py-2"> <i className="fa fa-eye"></i> View all countries</button></Link>
								</div>
							</div>
						// </ScrollAnimation>
					: ''
				}

				{/* Display popular colleges section */}
				{
					popular_colleges && popular_colleges.length > 0 ? 

						// <ScrollAnimation animateIn="fadeIn" delay={100} duration={1}>
							<div className="row p-4 popular_section animated fadeIn" id="popular_colleges_section">
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center">
									<h2 className="main_header"><b> Popular Colleges </b></h2>
									<hr className="hori_line"/>

									<h6 className="sub_header"> Viewed by most of the users</h6>

									<div className="row p-2">
										{
											popular_colleges.map((college) => {

												return <div key={college.id} className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-4">
													<div className="card">
														<div className="col p-0">
															<img src={college.col_banner && college.col_banner !== '' ? college.col_banner : process.env.PUBLIC_URL + '/home_bg_1.jpg'} alt="Banner" height="300" width="100%"/>
															<div className="bg_overlay"></div>

															{/* Favourite college */}
															{ <i className={college.favourite ? "fav_icon fa fa-heart" : " fav_icon fa fa-heart-o"} id={'fav_icon_' + college.id} onClick={() => this.handleFavouriteCollege(college.id)}></i> }
															
															<div className="mci_fmge_group">
																{ college.mci_approved === '1' ? <button className="mci_div"> MCI Approved </button> : ''}
																{ college.fmge_percentage ? <button className="fmge_div"> FMGE : {college.fmge_percentage} % </button> : ''}
															</div>

															<div className="col_details">
																<h5 className="col_name"> {college.col_name} </h5>
																<span className="col_country"> {college.college_city ? college.college_city.city_name + ',' : ''} { college.college_country ? college.college_country.con_name : '' }</span>
															</div>
														</div>
														<div className="card-footer">
															<div className="button-group">
																<button className="abroad_btn mr-2" onClick={() => this.handleCallback(college.col_country, college.id, '','')}> <i className="fa fa-phone"></i> Callback </button>
																<Link to={'/college_details/' + college.col_name}><button className="ml-2 abroad_btn"> <i className="fa fa-eye"></i> View college</button></Link>
															</div>
														</div>
													</div>			
												</div>

											})
										}
									</div>
									<Link to="/college_filters"><button className="ml-2 abroad_btn py-2"> <i className="fa fa-eye"></i> View all colleges</button></Link>
								</div>
							</div>
						// </ScrollAnimation>
					 : ''
				}

				{/* get in touch container */}
				{/* <ScrollAnimation animateIn="fadeIn" delay={100} duration={1}> */}
					<div className="row interested_container animated fadeIn">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
							<h2 className="main_header"><b> Interested? </b></h2>
							<h5 className="text-center mt-3"> Request a callback {is_auth && user_info ? '' : 'or Sign In' } to get in touch with us to know better about the colleges, study and work in abroad.</h5>
							<div className="mt-3">
								{is_auth && user_info ? '' : <button className="mr-3 mt-2 abroad_btn py-2 show_login"> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>}
								<button className="mt-2 abroad_btn py-2" onClick={() => this.handleCallback('', '','','')}> <i className="fa fa-phone mr-2"></i> REQUEST CALLBACK</button>
							</div>
						</div>
					</div>
				{/* </ScrollAnimation> */}

				{/* Favourite colleges container */}
				{
					favourite_colleges && favourite_colleges.length > 0 ?

						// <ScrollAnimation animateIn="fadeIn" delay={100} duration={1}>
							<div className="row p-4 popular_section animated fadeIn" id="favourite_colleges_section">
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center">
									<h2 className="main_header"><b> Favourite Colleges </b></h2>
									<hr className="hori_line"/>

									<h6 className="sub_header"> Your most favourite colleges</h6>
									
									<div className="row justify-content-center p-2">
									{
										favourite_colleges.map((college) => {
											
											return <div key={college.id}  className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-4">
												<div className="card">
													<div className="col p-0">
														<img src={college.col_banner && college.col_banner !== '' ? college.col_banner : process.env.PUBLIC_URL + '/home_bg_2.jpg'} alt="Banner" height="300" width="100%"/>
														<div className="bg_overlay"></div>
														<i className="fav_icon fa fa-heart"></i>
														
														<div className="mci_fmge_group">
															{ college.is_popular === '1' ? <button className="popular_div"> Popular </button> : ''}
															{ college.mci_approved === '1' ? <button className="mci_div"> MCI <i className="fa fa-check-circle"></i> </button> : ''}
															{ college.fmge_percentage ? <button className="fmge_div"> FMGE : {college.fmge_percentage} % </button> : ''}
														</div>

														<div className="col_details">
															<h5 className="col_name"> {college.col_name}</h5>
															<span className="col_country"> {college.con_name}</span>
														</div>
													</div>
													<div className="card-footer">
														<div className="button-group">
															<button className="abroad_btn mr-2" onClick={() => this.handleCallback(college.col_country, college.id,'','')}> <i className="fa fa-phone"></i> Callback </button>
															<Link to={'/college_details/' + college.col_name}><button className="ml-2 abroad_btn"> <i className="fa fa-eye"></i> View college</button></Link>
														</div>
													</div>
												</div>
											</div>
										})
									}
										
									</div>
									<Link to={{ pathname : '/college_filters', search : '?c_favourite=1' }}><button className="ml-2 abroad_btn py-2"> <i className="fa fa-eye"></i> View all colleges</button></Link>
								</div>
							</div>
						// </ScrollAnimation>
					: ''
				}

				{/* <a href="#home_container"><h4 className="text-center mt-5 up_arrow"><i className="fa fa-arrow-up"></i></h4></a> */}

			</div>

		);
	}

	componentWillUnmount () {
		this.props.onGetAutocompleteColleges('');
	}
}

const mapStatesToProps = (state) => {
	return {
		autocomplete_colleges : state.home.autocomplete_colleges,
		college_countries : state.country.college_countries,
		all_popular_countries : state.home.all_popular_countries,
		popular_countries : state.home.popular_countries,
		popular_colleges : state.home.popular_colleges,
		favourite_colleges : state.home.favourite_colleges,
		is_auth : state.home.is_auth,
		user_info : state.home.user_info,
	}
}

const mapActionsToProps = {
	onGetHomeDetails : getHomeDetails,
	onGetAutocompleteColleges : getAutocompleteColleges,
	onAddRemoveFavourite : addRemoveFavourite,
	onSetCallbackValues : setCallbackValues
}

export default connect(mapStatesToProps, mapActionsToProps) (Home);