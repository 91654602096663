import React from 'react';
import './CollegeCompare.css';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Autocomplete from '../Autocomplete/CompareAutocomplete';
import { setCallbackValues } from '../../actions/Callbacks';
import { compareColleges } from '../../actions/Colleges';
import { addRemoveFavourite } from '../../actions/Home';

class CollegeCompare extends React.Component {

  	constructor (props) {
		super (props);
		this.handleCallback = this.handleCallback.bind(this);
		this.handleLoginClick = this.handleLoginClick.bind(this);
		this.handleFavouriteCollege = this.handleFavouriteCollege.bind(this);
	}

  	handleCollegeCompare = (e) => {
		e.preventDefault();

		const formData = new FormData(document.getElementById('college_compare_form'));
		this.props.onCompareColleges(formData);
  	}

  	handleFavouriteCollege = (college_id) => {
		this.props.onAddRemoveFavourite(college_id);
		$('#fav_icon_' + college_id).toggleClass('fa-heart-o').toggleClass('fa-heart');
  	}

  	handleCallback = (country, college, stream, level) => {
		// Show side callback panel
		$('.callback_container').removeClass('d-none').removeClass('slideOutLeft').addClass('slideInLeft');

		this.props.onSetCallbackValues(country, college, stream, level);
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}

  	render () {

		const { is_auth, user_info, colleges, compare_collages } = this.props;

    	return (

			<div className="row mt-4 pt-4">
				<div className="col">
			
					<div className="compare_container text-center">

						<h4 className="header"> College compare </h4>
						<p className="para"> Select atleast 2 colleges to compare. </p>

						<form id="college_compare_form" onSubmit={this.handleCollegeCompare} autoComplete="off">

							<div className="row">
								<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center mt-2">
									<div className="input-group">
										
										<Autocomplete
											suggestions={ colleges && colleges.length > 0 ? colleges : []}
										/>

										<div className="input-group-append">
											<i className="fa fa-search input-group-text"></i>
										</div>
									</div>
								</div>

								<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center mt-2">
									<div className="input-group">
										
										<Autocomplete
											suggestions={ colleges && colleges.length > 0 ? colleges : []}
										/>

										<div className="input-group-append">
											<i className="fa fa-search input-group-text"></i>
										</div>
									</div>
								</div>

								<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center mt-2">
									<div className="input-group">

										<Autocomplete
											suggestions={ colleges && colleges.length > 0 ? colleges : []}
										/>

										<div className="input-group-append">
											<i className="fa fa-search input-group-text"></i>
										</div>
									</div>
								</div>

								<div className="col mt-3">
									<button type="submit" className="mt-1 abroad_btn py-2"> <i className="fa fa-check"></i> Compare colleges </button>
								</div>
							</div>

						</form>
						<br/>

						<div className="row mt-4">
						{
							compare_collages && compare_collages.length > 0 ?

								compare_collages.map((college) => (

									<div key={college.id} className="col">
										<div className="compare_cat college_header">

											<h6 className="sub_header"> <i className="fa fa-check-circle mr-1"></i> {college.col_name} </h6>
											
											{/* Favourite college */}
											{
												is_auth && user_info ?
												<i className={college.favourite ? "fav_icon fa fa-heart" : " fav_icon fa fa-heart-o"} id={'fav_icon_' + college.id} onClick={() => this.handleFavouriteCollege(college.id)}></i>
												: <i className="fav_icon fa fa-heart-o" onClick={this.handleLoginClick}></i>
											}
										</div>

										<div className="compare_cat">
											<span className="small_text"> Location </span>
											<p> {college.college_city.city_name ? college.college_city.city_name + ', ' : ''} {college.college_country.con_name} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Established </span>
											<p> {college.col_established ? college.col_established : 'NA'} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> FMGE pass percentage </span>
											<p> {college.fmge_percentage > 0 ? college.fmge_percentage + ' %' : 'NA'} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Popular </span>
											<p> {college.is_popular === '1' ? <i className="fa fa-check-circle text-success"></i> : <i className="fa fa-times-circle text-danger"></i>} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> MCI approved  </span>
											<p> {college.mci_approved === '1' ? <i className="fa fa-check-circle text-success"></i> : <i className="fa fa-times-circle text-danger"></i>} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Email ID </span>
											<p> {college.col_email ? college.col_email : 'NA'} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Contact </span>
											<p> {college.col_phone ? college.col_phone : 'NA'} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Address </span>
											<p> {college.col_address ? college.col_address : 'NA'} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Website </span>
											<p className="my-2"> {college.col_website ? <a href={college.col_website} target="_blank" rel="noopener noreferrer" className="abroad_btn"> Visit website </a> : 'NA'} </p>
										</div>

										<div className="compare_cat">
											<span className="small_text"> Google map location </span>
											<p className="my-2"> {college.col_map ? <a href={college.col_map} target="_blank" rel="noopener noreferrer" className="abroad_btn"> View map </a> : 'NA'} </p>
										</div>

										<div className="compare_cat py-2">
											<button className="abroad_btn" onClick={() => this.handleCallback(college.col_country, college.id, '', '')}> <i className="fa fa-phone mr-2"></i> Callback </button>

											<Link to={'/college_details/' + college.col_name}> <button className="abroad_btn ml-2"> <i className="fa fa-eye mr-2"></i> View college </button></Link>
										</div>

										<div className="compare_cat mt-4 p-2">
											<h5 className="course_header"> COURSES </h5>
											{
												college.courses && college.courses.length > 0 ? 

												<div className="text-left" id={'college_courses_' + college.id}>
												{
													college.courses.map((course, index) => (
														<div key={index}>
															<h6 className="my-2 course_check" data-toggle="collapse" data-target={'#college_courses_collapse_' + course.id} aria-expanded={index === 0 ? true : false}> <i className="fa fa-plus mr-1"></i> <i className="fa fa-minus mr-1"></i> {course.course_name} </h6>

															{/* College course details */}
															<div className={index === 0 ? "collapse text-center show ml-3" : "collapse text-center ml-3"} id={'college_courses_collapse_' + course.id} data-parent={'#college_courses_' + college.id}>

																<div className="compare_cat" >
																	<span className="small_text"> Stream & course level</span>
																	<p> { course.str_name} - { course.level_name} - {course.level_code} </p>
																</div>

																<div className="compare_cat">
																	<span className="small_text"> Course duration </span>
																	<p>{course.course_duration > 0 ? course.course_duration + ' months - (' + course.course_duration /12 + ' years)' : 'NA'}</p>
																</div>

																{
																	course.fees && course.fees !== '' ?
																	
																		<div className="compare_cat">
																			<span className="small_text"> Total course fees - {course.fees.year} </span>
																			{
																				is_auth && user_info ? <p>{course.fees.course_fees > 0 ?  college.college_country.con_cur_code + '. ' + course.fees.course_fees + ' / year' : 'NA'}</p> : <p><button className="abroad_btn mt-2" onClick={this.handleLoginClick}> <i className="fa fa-sign-in mr-1"></i> Login for fees </button></p>
																			}
																		</div>
																	: 
																		<div className="compare_cat">
																			<span className="small_text"> Total course fees </span>
																			<p> Fees not available.</p>
																		</div>
																}

															</div>
														</div>
													))
												}
												</div>
												: <p> No courses available. </p>
											}
											<br/>
										</div>
								
									</div>

								))
							: ''
						}
						</div>

					</div>

					{/* get in touch container */}
					<div className="row interested_container mt-4">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
							<h2 className="main_header"><b> Interested? </b></h2>
							<h5 className="text-center mt-3"> Request a callback {is_auth ? '' : 'or Sign In' } to get in touch with us to know better about the colleges, study and work in abroad.</h5>
							<div className="mt-3">
								{is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2 show_login"> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>}
								<button className="mt-2 abroad_btn py-2" onClick={() => this.handleCallback('', '','','')}> <i className="fa fa-phone mr-2"></i> REQUEST CALLBACK</button>
							</div>
						</div>
					</div>
			
				</div>
			</div>

    	)
  	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		user_info : state.home.user_info,
		colleges : state.home.autocomplete_colleges,
		compare_collages : state.college.compare_collages
	}
}

const mapActionsToProps = {
	onCompareColleges : compareColleges,
	onSetCallbackValues : setCallbackValues,
	onAddRemoveFavourite : addRemoveFavourite
}

export default connect(mapStatesToProps, mapActionsToProps) (CollegeCompare);