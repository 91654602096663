import { SET_CALLBACK_VALUES } from '../actions/Callbacks';

const initialState = {
    call_country : '',
    call_college : '',
    call_stream : '',
    call_course_level : ''
}

const Callback_reducer = (state = initialState, { type, payload }) => {
    
    switch (type)
    {
        case SET_CALLBACK_VALUES : 
            return Object.assign({}, state, { call_country : payload.country, call_college : payload.college, call_stream : payload.stream, call_course_level : payload.course_level });

        default :
            return state;
    }
}

export default Callback_reducer;