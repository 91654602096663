import React from 'react';
import './EditProfile.css';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { updateProfile } from '../../actions/Home';

class EditProfile extends React.Component {

	constructor (props) {
		super (props);
		this.state = { user : this.props.user_info };
		this.handleEditProfile = this.handleEditProfile.bind(this);
	}

  	componentWillMount () {
		if(!this.props.is_auth || !this.props.user_info)
		{
			window.location.hash = "#/";
		}
	}

	handleEditProfile = (e) => {
		const { name, value } = e.target;
		const { user } = this.state;
		user[name] = value;
		this.setState({ user : user });
	}

	handleUpdateProfile = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To update your profile?",
			buttons: ['Cancel', 'Update'],
		})
		.then(willRequest => {
			if (willRequest) {
				var formData = new FormData(document.getElementById('update_profile_form'));
				this.props.onUpdateProfile(formData);
			}
		});
	}

	render () {

		const { user_info, countries, streams, levels } = this.props;

		return (

			<div className="row mt-4 pt-4">
				<div className="col">
				
					<div className="edit_profile_container text-center">

						<h2 className="header"> {user_info.fname + ' ' + user_info.lname} </h2>

						<div className="row mt-3">
							<div className="col"></div>

							<div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
								<div className="edit_profile">
									<h4 className="sub_header"> <i className="fa fa-user-edit"></i> EDIT PROFILE </h4>

									<form className="mt-3" id="update_profile_form" onSubmit={this.handleUpdateProfile}>

										<div className="row">
											<div className="col-md-6 col-12">
												<div className="form-group">
													<label className="small_text"> First name * : </label>
													<input type="text" name="fname" className="form-control text-capitalize" placeholder="First name *" value={user_info.fname ? user_info.fname : ''} onChange={this.handleEditProfile} required/>
												</div>

												<div className="form-group">
													<label className="small_text"> Last name * : </label>
													<input type="text" name="lname" className="form-control text-capitalize" placeholder="Last name *" value={user_info.lname ? user_info.lname : ''} onChange={this.handleEditProfile} required/>
												</div>

												<div className="form-group">
													<label className="small_text"> Email ID * : </label>
													<input type="email" name="email" className="form-control" placeholder="Email ID *" value={user_info.email ? user_info.email : ''} onChange={this.handleEditProfile} required/>
												</div>
											
												<div className="form-group">
													<label className="small_text"> Mobile number * : </label>
													<input type="number" min="0" name="mobile" className="form-control" placeholder="Mobile Number *" value={user_info.mobile ? user_info.mobile : ''} onChange={this.handleEditProfile} required/>
												</div>
											</div>

											<div className="col-md-6 col-12">
												<div className="form-group">
													<label className="small_text"> Native country : </label>
													<select name="country" className="form-control" value={user_info.country ? user_info.country : ''} onChange={this.handleEditProfile}>
														<option value=""> Native country </option>
														{
															countries && countries.length > 0 ?
															countries.map((country) => {
																return <option key={country.id} value={country.id}>{country.con_name}</option>
															}) : ''
														}
													</select>
												</div>

												<div className="form-group">
													<label className="small_text"> Native city : </label>
													<input type="text" name="city" className="form-control" placeholder="Enter your city" value={user_info.city ? user_info.city : ''} onChange={this.handleEditProfile} />
												</div>

												<div className="form-group">
													<label className="small_text"> Interested country : </label>
													<select name="int_country" className="form-control" value={user_info.int_country ? user_info.int_country : ''} onChange={this.handleEditProfile}>
														<option value=""> Interested country </option>
														{
															countries && countries.length > 0 ?
															countries.map((country) => {
																return <option key={country.id} value={country.id}>{country.con_name}</option>
															}) : ''
														}
													</select>
												</div>

												<div className="form-group">
													<label className="small_text"> Interested stream and course level : </label>
													<div className="input-group">
														<select name="int_stream" className="form-control" value={user_info.int_stream ? user_info.int_stream : ''} onChange={this.handleEditProfile}>
															<option value=""> Interested stream </option>
															{
																streams && streams.length > 0 ?
																streams.map((stream) => {
																	return <option key={stream.id} value={stream.id}>{stream.str_name}</option>
																}) : ''
															}
														</select>

														<select name="int_course_level" className="form-control ml-2" value={user_info.int_course_level ? user_info.int_course_level : ''} onChange={this.handleEditProfile}>
															<option value=""> Interested level </option>
															{
																levels && levels.length > 0 ?
																levels.map((level) => {
																	return <option key={level.id} value={level.id}>{level.level_name}</option>
																}) : ''
															}
														</select>
													</div>
												</div>

											</div>
										</div>

										<div className="form-group mt-4">
											<button type="submit" className="abroad_btn"> <i className="fa fa-check"></i> UPDATE PROFILE </button>
										</div>

									</form>
								</div>
							</div>

							<div className="col"></div>
						</div>
					</div>

				</div>
			</div>

		)
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		user_info : state.home.user_info,
		countries : state.country.college_countries,
		streams : state.list.streams,
		levels : state.list.course_levels
	}
}

const mapActionsToProps = {
	  onUpdateProfile : updateProfile,
}

export default connect(mapStatesToProps, mapActionsToProps) (EditProfile);