import React from 'react';
import './Callback.css';
import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import { connect } from 'react-redux';
import { getStreams, getCourseLevels, getCountryColleges } from '../../actions/Listings';
import { requestCallback, setCallbackValues } from '../../actions/Callbacks';
import swal from 'sweetalert';

class Callback extends React.Component {

	constructor (props) {
		super (props);
		this.state = { moment : moment() };
		this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
	}

	componentWillMount () {
		this.props.onGetCountryColleges(0);
		this.props.onGetStreams();
		this.props.onGetCourseLevels();
	}

	handleDateTimeChange = (moment) => {
		this.setState({ moment : moment });
	}

	handleValueChange = (e) => {
		if(e.target.name === 'int_country')
		{
			this.props.onGetCountryColleges(e.target.value);
			this.props.onSetCallbackValues(e.target.value, '', this.props.call_stream, this.props.call_course_level);
		}
		else if(e.target.name === 'int_college')
			this.props.onSetCallbackValues(this.props.call_country, e.target.value, this.props.call_stream, this.props.call_course_level);
		else if(e.target.name === 'int_stream')
			this.props.onSetCallbackValues(this.props.call_country, this.props.call_college, e.target.value, this.props.call_course_level);
		else if(e.target.name === 'int_course_level')
			this.props.onSetCallbackValues(this.props.call_country, this.props.call_college, this.props.call_stream, e.target.value );
	}

	handleRequestCallback = (e) => {
		e.preventDefault();

		swal({
			title: "Are you sure?",
			text: "To request a callback?",
			buttons: ['Cancel', 'Request'],
		})
		.then(willRequest => {
			if (willRequest) {
				var formData = new FormData(document.getElementById('callback_form'));
				this.props.onRequestCallback(formData);
			}
		});
	}
	  
	render() {

		const { is_auth, user_info, countries, country_colleges, streams, course_levels, call_country, call_college, call_stream, call_course_level } = this.props;

		const shortcuts = {
			'Tomorrow': moment().add(1, 'days'),
			'Day after': moment().add(2, 'days')
		};

		return (

			<div className="interested_side_container text-center">
				<h4> INTERESTED? </h4>
				<span className="small_text"> Submit your details to get callback from us.</span>

				<div className="college_callback text-center mt-2">
					<form id="callback_form" onSubmit={this.handleRequestCallback} autoComplete="off">
						<div className="form-group">
							{/* <label className="small_text"> Full name : </label> */}
							<input type="text" name="name" className="form-control" defaultValue={ is_auth && user_info ? user_info.fname + ' ' + user_info.lname : ''} placeholder="Full Name *" />
						</div>
						<div className="form-group">
							{/* <label className="small_text"> Contact Number : </label> */}
							<input type="text" min="0" name="mobile" className="form-control" defaultValue={is_auth && user_info ? user_info.mobile : ''} placeholder="Contact number *" />
						</div>
						<div className="form-group">
							{/* <label className="small_text"> Email ID : </label> */}
							<input type="email" name="email" className="form-control" defaultValue={is_auth && user_info ? user_info.email : ''} placeholder="Email ID *" />
						</div>
						<div className="form-group">
							{/* <label className="small_text"> Interested country : </label> */}
							<select name="int_country" className="form-control" value={call_country ? call_country : ''} onChange={this.handleValueChange} >
								<option value="">Interested country</option>
								{
									countries && countries.length > 0 ?
									countries.map((country) => {
										return <option key={country.id} value={country.id}> {country.con_name} </option>
									}) : ''
								}
							</select>

						</div>
						<div className="form-group">
							{/* <label className="small_text"> Interested college : </label> */}
							<select name="int_college" className="form-control" value={call_college ? call_college : ''} onChange={this.handleValueChange}>
								<option value="">Interested college</option>
								{
									country_colleges && country_colleges.length > 0 ?
									country_colleges.map((college) => {
										return <option key={college.id} value={college.id}> {college.col_name} </option>
									}) : ''
								}
							</select>
						</div>
						<div className="form-group">
							{/* <label className="small_text"> Interested stream : </label> */}
							<select name="int_stream" className="form-control" value={call_stream ? call_stream : ''} onChange={this.handleValueChange}>
								<option value="">Interested stream</option>
								{
									streams && streams.length > 0 ?
									streams.map((stream) => {
										return <option key={stream.id} value={stream.id}> {stream.str_name} </option>
									}) : ''
								}
							</select>
						</div>
						<div className="form-group">
							{/* <label className="small_text"> Interested course level : </label> */}
							<select name="int_course_level" className="form-control" value={call_course_level ? call_course_level : ''} onChange={this.handleValueChange}>
								<option value="">Interested course level</option>
								{
									course_levels && course_levels.length > 0 ?
									course_levels.map((level) => {
										return <option key={level.id} value={level.id}> {level.level_name} </option>
									}) : ''
								}
							</select>
						</div>

						<div className="form-group">
							<label className="small_text">Select date and time when you want callback.</label>
							<DatetimePickerTrigger
								shortcuts={shortcuts} 
								moment={this.state.moment}
								onChange={this.handleDateTimeChange}
								weeks={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
								minDate={moment()}
								hideDisabledOptions={false}
								maxDate={moment().add(60, 'days')} >
								<input type="text" name="int_date_time" className="form-control" defaultValue={this.state.moment.format('YYYY-MM-DD HH:mm')}  />
							</DatetimePickerTrigger>
						</div>

						<div className="form-group">
							<textarea name="message" className="form-control" rows="4" placeholder="Any queries?" />
						</div>

						<div className="form-group">
							<button type="submit" className="abroad_btn"> <i className="mr-1 fa fa-phone"></i> SUBMIT REQUEST </button>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

const mapStatesToProps = (state) => {
	return {
		is_auth : state.home.is_auth,
		user_info : state.home.user_info,
		countries : state.country.college_countries,
		country_colleges : state.list.country_colleges,
		streams : state.list.streams,
		course_levels : state.list.course_levels,
		call_country : state.callback.call_country,
		call_college : state.callback.call_college,
		call_stream : state.callback.call_stream,
		call_course_level : state.callback.call_course_level
	}
}

const mapActionsToProps = {
	onGetCountryColleges : getCountryColleges,
	onGetStreams : getStreams,
	onGetCourseLevels : getCourseLevels,
	onRequestCallback : requestCallback,
	onSetCallbackValues : setCallbackValues
}

export default connect(mapStatesToProps, mapActionsToProps) (Callback);
