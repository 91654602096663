import React from 'react';
import './CollegeFilters.css';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import $ from 'jquery';
import { connect } from 'react-redux';
import Loader from '../../assets/loader.gif';
import NoResultsIcon from '../../assets/no_results.png';
import queryString from 'query-string';
import { getFilterColleges } from '../../actions/Colleges';
import { addRemoveFavourite } from '../../actions/Home';
import { setCallbackValues } from '../../actions/Callbacks';
import ScrollAnimation from 'react-animate-on-scroll';

class CollegeFilters extends React.Component {

  constructor (props) {
    super(props);
    this.state = { active_page : 1, params : queryString.parse(this.props.location.search, ) };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  componentWillMount () {
    this.props.onGetFilterColleges(this.props.location.search);
  }

  clearFiltersHandler = (e) => {
    window.location.hash = '#/college_filters';
    window.location.reload();
  }

  changeFilters = (e) => {
    this.setState({ active_page : 1 });
    var filtersData = $('#college_filters_form').serialize();
    var pageData = $('#college_pagination_form').serialize();
    var streams_data = '', levels_data = '', fmge_data = '';

    $('.streams_check:checked').each(function(index){
			streams_data += index > 0 ? ',' + $(this).val() : '' + $(this).val();
    })
    
    $('.levels_check:checked').each(function(index){
			levels_data += index > 0 ? ',' + $(this).val() : '' + $(this).val();
		})

		$('.fmge_check:checked').each(function(index){
			fmge_data += index > 0 ? ',' + $(this).val() : '' + $(this).val();
    })

    pageData += '&c_page_no=1&c_streams=' + streams_data + '&c_levels=' + levels_data + '&c_fmge=' + fmge_data;
    this.props.onGetFilterColleges('?' + filtersData + '&' + pageData);
  }

  handlePageChange = (page_number) => {
    this.setState({ active_page : page_number });
    var filtersData = $('#college_filters_form').serialize();
    var pageData = $('#college_pagination_form').serialize();
    var streams_data = '', levels_data = '', fmge_data = '';

    $('.streams_check:checked').each(function(index){
			streams_data += index > 0 ? ',' + $(this).val() : '' + $(this).val();
    })
    
    $('.levels_check:checked').each(function(index){
			levels_data += index > 0 ? ',' + $(this).val() : '' + $(this).val();
		})

		$('.fmge_check:checked').each(function(index){
			fmge_data += index > 0 ? ',' + $(this).val() : '' + $(this).val();
    })

    pageData += '&c_page_no=' + page_number + '&c_streams=' + streams_data + '&c_levels=' + levels_data + '&c_fmge=' + fmge_data;
    this.props.onGetFilterColleges('?' + filtersData + '&' + pageData);
  }

  handleFavouriteCollege = (college_id) => {
    this.props.onAddRemoveFavourite(college_id);
    $('#fav_icon_' + college_id).toggleClass('fa-heart-o').toggleClass('fa-heart');
  }

  handleCallback = (country, college, stream, course_level) => {
		// Show side callback panel
		$('.callback_container').removeClass('d-none').removeClass('slideOutLeft').addClass('slideInLeft');

		this.props.onSetCallbackValues(country, college, stream, course_level);
  }
  
  handleLoginClick = (e) => {
    // Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
  }

	render() {

    const { active_page, params } = this.state;
    const { sub_loader, colleges, page_count, page_size, total_count, countries, is_auth, user_info, streams, levels } = this.props;

		return (

			<div className="row countries_container pt-5">
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
				
					{/* Breadcrumbs and results counter container */}
					<div className="row sort_container">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
              <nav area-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/">Home</Link> </li>
									<li className="breadcrumb-item active" area-current="page">Colleges</li>
								</ol>
							</nav>
						</div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
              <button className="clear_btn float-left" onClick={this.clearFiltersHandler}> Clear filters </button>
              <span className="small_text text-blue"> Showing {page_count} of {total_count} colleges.</span>
						</div>
					</div>

					{/*  Countries display container */}
					<div className="row px-1">
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 px-2 mb-4">

              <form id="college_filters_form" autoComplete="off">

                <div className="filters_card">

                  <h6 className="filter_header" data-toggle="collapse" data-target="#search_collapse"> Search <i className="fa fa-chevron-down"></i></h6>
                  
                  <div className="filter_div collapse show" id="search_collapse">
                    <div className="input-group p-1">
                      <input type="search" name="c_search_key" className="form-control search_input" defaultValue={params.c_search_key ? params.c_search_key : '' } onChange={this.changeFilters} placeholder="Search college or country" />
                      <div className="input-group-append">
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                  </div>

                  <h6 className="filter_header" data-toggle="collapse" data-target="#sort_collapse"> Sorting <i className="fa fa-chevron-down"></i></h6>
                  
                  <div className="filter_div collapse show pl-2" id="sort_collapse">
                    <input type="radio" name="c_sort" className="sort_check" defaultValue="1" onChange={this.changeFilters} /> <span className="check_label"> Colleges A-Z</span><br/>
                    <input type="radio" name="c_sort" className="sort_check" defaultValue="2" onChange={this.changeFilters} /> <span className="check_label"> Colleges Z-A</span><br/>
                    <input type="radio" name="c_sort" className="sort_check" defaultValue="3" onChange={this.changeFilters} /> <span className="check_label"> Countries A-Z</span><br/>
                    <input type="radio" name="c_sort" className="sort_check" defaultValue="4" onChange={this.changeFilters} /> <span className="check_label"> Countries Z-A</span><br/>
                    <input type="radio" name="c_sort" className="sort_check" defaultValue="5" onChange={this.changeFilters} defaultChecked="true" /> <span className="check_label"> Popularity</span><br/>
                    <input type="radio" name="c_sort" className="sort_check" defaultValue="6" onChange={this.changeFilters} /> <span className="check_label"> FMG Passing High-Low</span>
                  </div>

                  <h6 className="filter_header" data-toggle="collapse" data-target="#filter_collapse"> Filters <i className="fa fa-chevron-down"></i></h6>

                  <div className="filter_div show" id="filter_collapse">

                    <div className="filter_selector">
                      <h6 className="filter_sub_header"> Popular / MCI {is_auth ? '/ Favourites' : ''} </h6>
                      <input type="checkbox" name="c_popular" defaultChecked={params.c_popular ? true : false} onChange={this.changeFilters} /> <span className="check_label"> Popular colleges</span><br/>
                      <input type="checkbox" name="c_mci" onChange={this.changeFilters} /> <span className="check_label"> MCI Approved</span><br/>
                      {is_auth ? <div><input type="checkbox" name="c_favourite" defaultChecked={params.c_favourite ? true : false} onChange={this.changeFilters} /> <span className="check_label"> Favourites</span></div> : ''}
                    </div>

                    <div className="filter_selector">
                      <h6 className="filter_sub_header"> Country </h6>
                      <select name="c_country" className="form-control" defaultValue={params.c_country ? params.c_country : '' } onChange={this.changeFilters}>
                        <option value="">Choose country</option>
                        {
                          countries && countries.length > 0 ?
                            countries.map((country) => (
                              <option key={country.id} value={country.id}>{country.con_name}</option>
                            )) : ''
                        }
                      </select>
                    </div>

                    <div className="filter_selector">
                      <h6 className="filter_sub_header"> Streams </h6>
                      {
                        streams && streams.length > 0 ? streams.map((stream) => (
                          <div key={stream.id}><input type="checkbox" className="streams_check" defaultValue={stream.id} onChange={this.changeFilters} /> <span className="check_label"> {stream.str_name} </span><br/></div>
                        )) : ''
                      }
                    </div>

                    <div className="filter_selector">
                      <h6 className="filter_sub_header"> Course Levels </h6>
                      {
                        levels && levels.length > 0 ? levels.map((level) => (
                          <div key={level.id}><input type="checkbox" className="levels_check" defaultValue={level.id} onChange={this.changeFilters} /> <span className="check_label"> {level.level_name} </span><br/></div>
                        )) : ''
                      }
                    </div>

                    <div className="filter_selector">
											<h6 className="filter_sub_header"> FMGE pass percentage </h6>
											<input type="checkbox" className="fmge_check" defaultValue="0_30" onChange={this.changeFilters} /> <span className="check_label"> Below 30</span><br/>
											<input type="checkbox" className="fmge_check" defaultValue="31_50" onChange={this.changeFilters} /> <span className="check_label"> 31 - 50</span><br/>
											<input type="checkbox" className="fmge_check" defaultValue="51_70" onChange={this.changeFilters} /> <span className="check_label"> 51 - 70</span><br/>
											<input type="checkbox" className="fmge_check" defaultValue="71_90" onChange={this.changeFilters} /> <span className="check_label"> 71 - 90</span><br/>
											<input type="checkbox" className="fmge_check" defaultValue="91_100" onChange={this.changeFilters} /> <span className="check_label"> More than 90</span>
										</div>
        
                    {/* <div className="filter_selector">
                      <h6 className="filter_sub_header"> Course Fees </h6>
                      <input type="checkbox" /> <span className="check_label"> 100000 - 200000</span><br/>
                      <input type="checkbox" /> <span className="check_label"> 200001 - 500000</span><br/>
                      <input type="checkbox" /> <span className="check_label"> 500001 - 100000</span>
                    </div> */}

                  </div>
                </div>
              </form>
						</div>

            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 px-4 filter_results">
              {
                sub_loader ? <img src={Loader} className="filter_loader" alt="Loader" /> :

                <div className="">
                  {
                    colleges && colleges.length > 0 ? colleges.map((college) => (

                      <div key={college.id}>
                        {/* <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}> */}
                          <div className="row college_card p-2">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                              <img src={college.col_logo && college.col_logo !== '' ? college.col_logo : process.env.PUBLIC_URL + '/col_logo.png'} alt="Logo"/>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                              <Link to={'/college_details/' + college.col_name}><h5 className="text-capitalize"> {college.col_name} </h5></Link>
                              <span className="small_text"> {college.city_name !== '' ? college.city_name + ',' : ''} {college.con_name !== '' ? college.con_name : ''}</span><br/>
                              <span className="small_text"> {college.col_established ? 'Established : ' + college.col_established : ''} </span><br/>
                              <span className="small_text"> {college.col_accrs ? 'Accreditations : ' + college.col_accrs : ''} </span><br/>
                              
                              {/* Favourite college */}
                              {
                                is_auth && user_info ?
                                <i className={college.favourite ? "fav_icon fa fa-heart" : " fav_icon fa fa-heart-o"} id={'fav_icon_' + college.id} onClick={() => this.handleFavouriteCollege(college.id)}></i>
                                : <i className="fav_icon fa fa-heart-o" onClick={this.handleLoginClick}></i>
                              }
                              
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center approved_div p-2">
                              {college.is_popular === '1' ? <span className="popular_div"> Popular</span> : ''}
                              {college.mci_approved === '1' ? <span className="mci_div"> MCI Approved</span> : ''}
                              {college.fmge_percentage > 0 ? <span className="fmge_div"> FMGE : {college.fmge_percentage} % </span> : ''}
                            
                              <button className="abroad_btn mx-1" onClick={() => this.handleCallback(college.col_country, college.id, '', '')}> <i className="fa fa-phone"></i> Callback </button>
                              { college.courses && college.courses.length > 0 ? <button className="abroad_btn mx-1" data-toggle="collapse" data-target={"#college_courses_collapse_" + college.id} > <i className="fa fa-eye"></i> View courses</button> : ''}
                              <Link to={ '/college_details/' + college.col_name}><button className="abroad_btn mx-1"> <i className="fa fa-eye"></i> View college</button></Link>
          
                              {/* College courses display */}
                              { college.courses && college.courses.length > 0 ?
                                <div className="collapse course_container" id={"college_courses_collapse_" + college.id}>
                                  <div className="row text-left">
                                  {
                                    college.courses.map((course) => (
                                      <div key={course.id} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <p> <i className="fa fa-check-circle" /> {course.course_name} - {course.str_name} - {course.level_code} { is_auth && user_info && course.fees.length > 0 ? ' - ' + college.con_cur_code + '. ' + course.fees[0].course_fees : ''} </p>
                                      </div>
                                    ))
                                  }

                                  </div>
                                </div>
                                : ''
                              }
          
                            </div>
                          </div>
                        {/* </ScrollAnimation> */}
                      </div>
                    )) :  <div className="col text-center no_results p-3">
                            <img src={NoResultsIcon} className="" alt="No results" height="100" /><br/><br/>
                            <h6> No matching results found.</h6>
                          </div>
                  }
                </div>
              }

              {/*  Pagination container  */}
              {
                !sub_loader && total_count > 10 ?

                  <form id="college_pagination_form" autoComplete="off">

                    <div className="row pt-2">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left text-blue">
                        <span className="small_text">View </span>
                        <select name="c_page_size" className="form-control ml-2 w-auto d-inline" value={page_size} onChange={this.changeFilters}>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        <span className="small_text ml-2">results per page. </span>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">
                        <div className="pagination_container table-responsive">
                          <Pagination
                            activePage={active_page}
                            itemsCountPerPage={page_size}
                            totalItemsCount={total_count}
                            pageRangeDisplayed={6}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form> : ''
              }
              
            </div>
          </div>

					{/* get in touch container */}
          <div className="row interested_container">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
              <h2 className="main_header"><b> Interested? </b></h2>
              <h5 className="text-center mt-3"> Request a callback {is_auth ? '' : 'or Sign In' } to get in touch with us to know better about the colleges, study and work in abroad.</h5>
              <div className="mt-3">
                {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2" onClick={this.handleLoginClick}> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>}
                <button className="mt-2 abroad_btn py-2" onClick={() => this.handleCallback('', '','','')}> <i className="fa fa-phone mr-2"></i> REQUEST CALLBACK</button>
              </div>
            </div>
          </div>

				</div>
			</div>

		);
  }
  
}

const mapStatesToProps = (state) => {
  return {
    colleges : state.college.colleges,
    page_count : state.college.page_count,
    page_size : state.college.page_size,
    total_count : state.college.total_count,
    sub_loader : state.home.sub_loader,
    countries : state.country.college_countries,
    is_auth : state.home.is_auth,
    user_info : state.home.user_info,
    streams : state.list.streams,
    levels : state.list.course_levels
  }
}

const mapActionsToProps = {
  onGetFilterColleges : getFilterColleges,
  onAddRemoveFavourite : addRemoveFavourite,
  onSetCallbackValues : setCallbackValues
}

export default connect(mapStatesToProps, mapActionsToProps) (CollegeFilters);