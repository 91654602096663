import React from 'react';
import './Checkout.css';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link }from 'react-router-dom';
import { setCallbackValues } from '../../actions/Callbacks';
import { getPlanBenefits, getPlanDetails } from '../../actions/Listings';
import CheckIcon from '../../assets/check.png';
import SaleIcon from '../../assets/sale.png';
import Logo from '../../assets/logo.png';
import server from '../../utils/Server';

class Checkout extends React.Component {

  constructor (props) {
    super (props);
    this.state = { plan_name : this.props.match.params.plan_name };
    this.handleCallback = this.handleCallback.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  componentWillMount = () => {
    this.props.onGetPlanBenefits();
    this.props.onGetPlanDetails(this.state.plan_name);
  }

  handleCheckOut = (e) => {
    e.preventDefault();

    // Check if user logged in
    if(this.props.is_auth && this.props.user_info && this.props.user_info !== '')
    {
      var user_info = this.props.user_info;
      var plan = this.props.plan;

      // Check if plan selected
      if(plan && plan !== '')
      {
        var amount = plan.offer_price > 0 && plan.offer_price <= plan.plan_price ? plan.offer_price : plan.plan_price;
        var formData = new FormData(document.getElementById('checkout_form'));
        
        server.post('/save_checkout', formData)
          .then(response => {
            if(response.data.code === 200)
            {
              var order_id = response.data.order_id;

              // Initialize payment
              var options = {
                "key": "rzp_live_fRZhygNs7HIRwh",
                "amount": (amount * 100),
                "name": plan.plan_name,
                "description": "GooCampus Edu Solutions",
                "image": Logo,
                "order_id" : order_id,
                "theme" : {
                  "color": "#44a1a0"
                },
                "handler": function (response){
                  if(response && response.razorpay_payment_id !== '')
                  {
                    // Make payment success in server
                    server.post('/payment_success', response)
                      .then(resp => {
                        if(resp.data.code === 200)
                        {
                          // Redirecting to success page after successfull payment
                          window.location.href = '/#/payment_success/' + this.state.plan_name;
                        }
                    });
                  }
                },
                "prefill" : {
                  "contact" : user_info.mobile,
                  "email" : user_info.email
                }
              }
      
              let rzp = new window.Razorpay(options); rzp.open();
            }
        })

      }
    }
    else
    {
      this.handleLoginClick();
    }
  }

  handleCallback = (country, college, stream, level) => {
		// Show side callback panel
		$('.callback_container').removeClass('d-none').removeClass('slideOutLeft').addClass('slideInLeft');

		this.props.onSetCallbackValues(country, college, stream, level);
	}

  handleLoginClick = (e) => {
    // Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
  }

  render () {

    const { is_auth, benefits, plan } = this.props;

    return (

      <div className="row mt-4 pt-4">
        <div className="col">
          
          <div className="container checkout_container text-center">

		  	  <h4 className="header"> CHECKOUT</h4>
            <span className="small_text"> You are just one step away from payment, select your payment method. </span>
            <br/>

            <div className="row">

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-4">
                {
                  plan && plan !== '' ?

                  <div className="pricing_package">
                    <div className="package_header">
                      <h5 className="text-uppercase"> {plan.plan_name} </h5>
                      <img src={CheckIcon} className="check_icon" alt="Check Icon" />

                      {
                        plan.offer_price > 0 && plan.offer_price < plan.plan_price ?
                          <div>
                            <span className="actual_price"> <i className="fa fa-rupee"></i> {plan.plan_price} </span><br/>
                            <h1> <i className="fa fa-rupee"></i> {plan.offer_price} <img src={SaleIcon} alt="Offer" className="offer_banner" /> </h1>
                            {/* {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} {Math.round((plan.plan_price - plan.offer_price) / plan.plan_price * 100 )}% off. </strong></span> : ''} */}
                            {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} </strong></span> : ''}
                          </div>
                        : <h1> <i className="fa fa-rupee"></i> {plan.plan_price} </h1>
                      }
        
                    </div>

                    <div className="package_body">
                      {
                        benefits && benefits.length > 0 ?
                          benefits.map((benefit) => (
                            plan.plan_benefits && plan.plan_benefits.includes(benefit.id) ? 
                              <p className="benefit_true" key={benefit.id}><i className="fa fa-check mr-1"></i> {benefit.benefit} </p>
                            : <p className="benefit_false" key={benefit.id}><i className="fa fa-ban mr-1"></i> {benefit.benefit} </p>
                          ))
                        : ''
                      }
                    </div>

                  </div>
                  : ''
                }
              </div>

                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 p-4">
                  <div className="pricing_package checkout_package p-3">

                  <h2> CHECKOUT </h2>
                  <br/>

                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-4 text-justify">
                      <p>Thank you for your patronage. Once you make payment, you will receive an official communication from our team, along with an invoice. If you do not receive an invoice within 48 hours of making payment, please reach out to finance@goocampus.in.</p>
                      <br/>
                      <Link to="/refund_policy"> Refund and cancellation policy </Link>
                      <br/>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-3 col-12">

                      <form id="checkout_form" onSubmit={this.handleCheckOut} autoComplete="off">

                        <input type="hidden" name="plan_id" defaultValue={plan.id} />
                        
                        <h4 className="header"> Plan : {plan.plan_name} </h4>
                        {
                          plan.offer_price > 0 && plan.offer_price < plan.plan_price ?
                            <div>
                              Actual price : <span className="actual_price text_blue"> <i className="fa fa-rupee"></i> {plan.plan_price} </span><br/>
                              <h1 className="header"> Total : <i className="fa fa-rupee"></i> {plan.offer_price} </h1>
                              <span> You saved : <i className="fa fa-rupee"></i> {plan.plan_price - plan.offer_price} </span><br/>
                              {/* {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} {Math.round((plan.plan_price - plan.offer_price) / plan.plan_price * 100 )}% off. </strong></span> : ''} */}
                              {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} </strong></span> : ''}
                            </div>
                          : <div>
                              <h1 className="header"> Total : <i className="fa fa-rupee"></i> {plan.plan_price} </h1>
                            </div>
                        }
                        
                        <span className="small_text">Amount is in Indian Rupees - INR, Inclusive of all taxes.</span>
                        <br/>
                        <div className="text-center p-2">
                          {/* <button className="abroad_btn mt-3"> <i className="fa fa-check mr-1"></i> PROCEED TO PAY </button> */}
                
                          <button type="submit" className="abroad_btn mt-3"> <i className="fa fa-check mr-1"></i> PROCEED TO PAY </button>

                          <Link to="/pricing"><button type="button" className="abroad_btn cancel_btn mt-3"> <i className="fa fa-rupee mr-1"></i> BACK TO PRICING </button></Link>
                        
                          <br/>
                        </div>
                      
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          {/* get in touch container */}
          <div className="row interested_container">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
              <h2 className="main_header"><b> Interested? </b></h2>
              <h5 className="text-center mt-3"> Request a callback {is_auth ? '' : 'or Sign In' } to get in touch with us to know better about the colleges, study and work in abroad.</h5>
              <div className="mt-3">
                {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2 show_login"> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>}
                <button className="mt-2 abroad_btn py-2" onClick={() => this.handleCallback('', '','','')}> <i className="fa fa-phone mr-2"></i> REQUEST CALLBACK</button>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    )
  }
}

const mapStatesToProps = (state) => {
  return {
    is_auth : state.home.is_auth,
    user_info : state.home.user_info,
    benefits : state.list.benefits,
    plan : state.list.member_plan,
    order_id : state.home.order_id
  }
}

const mapActionsToProps = {
  onGetPlanBenefits :getPlanBenefits,
  onGetPlanDetails : getPlanDetails,
  onSetCallbackValues : setCallbackValues,
}

export default connect(mapStatesToProps, mapActionsToProps) (Checkout);