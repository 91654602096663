import React from 'react';
import './AboutUs.css';
import { connect } from 'react-redux';
import $ from 'jquery';
import { setCallbackValues } from '../../actions/Callbacks';
import Logo from '../../assets/logo.png';
import ScrollAnimation from 'react-animate-on-scroll';

class AboutUs extends React.Component {

  constructor (props) {
    super (props);
    this.handleCallback = this.handleCallback.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleCallback = (country, college, stream, level) => {
		// Show side callback panel
		$('.callback_container').removeClass('d-none').removeClass('slideOutLeft').addClass('slideInLeft');

		this.props.onSetCallbackValues(country, college, stream, level);
  }
  
  handleLoginClick = (e) => {
    // Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
  }

  render () {

    const { is_auth } = this.props;

    return (

      <div className="row">
        <div className="col about_us_container">

          <div className="row description_card">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header">About GooCampus </h4>
              <p className="para">GooCampus is an educational counselling & student assistance organization in Bangalore with extensive experience of mentoring students, providing career guidance and admission assistance.</p>
              <p className="para">We specialize in guiding medical aspirants and have guided more than 7000 students in the past 6 years. We have our presence in Bangalore, Hyderabad and Trivandrum providing guidance to students on career options.</p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-5 text-center">
              <img src={Logo} alt="Logo" height="100" />
            </div>
          </div>

          <div className="row description_card">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header">What We Do ? </h4>
              <p className="para">Over the past few years, we have observed that aspirants are not aware of the entire NEET admission process. Some have queries regarding the application process, while others get confused with the option-entry round.</p>
              <p className="para">The new admission process does not make it easy for a new candidate to secure admissions, with all the hurdles & challenges that they face.</p>
              <p className="para">We want to empower students with the information they require to successfully navigate through the entire NEET admission process, right from the application stage all the way till admission closure. By giving them relevant information at the right time, candidates can sail through the process and safely complete all the admission requirements.</p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <h4 className="header"> How We Help ?</h4>
              <p className="para">We can provide you with information about the NEET counselling process from start to finish, so you’re all caught up.</p>
              <p className="para">We will explain the post-examination process and walk you through the stages step-by-step. </p>
              <p className="para">You can interact directly with senior NEET counsellors and can clarify all their doubts regarding the process, rules & regulations.</p>
              <p className="para">We give you the opportunity to speak directly with senior medical professionals, specialists in their branch of medicine. You can ask them anything about the medical profe</p>
            </div>
          </div>

          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}>
            <div className="row description_card">
              <div className="col">
                <h2 className="service_header"> Our Services</h2>
              </div>

              <div className="row pt-0 mt-0">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <h4 className="header"> Expert Counselling </h4>
                  <p className="para">We have an expert team of NEET counsellors who can clarify all your queries and walk you through the entire admission process step-by-step. The more candidates know about the process, the more confident they will be during the process.</p>
                  <p className="para">Information about the counselling process also makes candidates and parents vigilant and reduces the chances of them getting swindled or cheated by fake admission agents.</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <h4 className="header"> Documentation </h4>
                  <p className="para">One of the most commonly observed mistake candidates make is not having the right documentation during the counselling process. If the counselling authority is not satisfied with your documentation, they can outright reject your application, bringing your dreams of becoming a doctor to a quick & sudden stop.</p>
                  <p className="para">We know all about the documentation process, so we keep you ready to sail through the document verification process without so much as a hiccup.</p>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}>
            <div className="row description_card">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Rules &amp; Regulations </h4>
                <p className="para">The rules of the admission process change from state to state and differ when candidates are applying for central universities. It&rsquo;s hard for any candidate to know all these rules, as they keep changing from time to time. Our team keeps track of all these changes, no matter how minute and keep the candidates updated on all these changes.</p>
                <p className="para">&nbsp;We do the information gathering, so you can focus on your preparations and post-exam counselling process. There are also ways to use the rules &amp; guidelines to boost a candidate&rsquo;s chances of securing a seat, that&rsquo;s where our expertise and experience plays a major role.</p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <h4 className="header">Application Support </h4>
                  <p className="para">The admission cycle starts even before the candidates appear for the exam, one of the most common mistakes candidates make are during the application filling stage. Candidates have a lot of queries and doubts and usually end up messing up the form, and they don&rsquo;t get a second chance at it. This is where we come in, our team walks candidates through the appropriate way to fill the application and make sure all the relevant details are filled in.</p>
                  <p className="para">One mistake here and it could very well cost the candidate their medical seat.</p>
                </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}>
            <div className="row description_card">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Choice Entry </h4>
                <p className="para">Choice entry (also called Option Entry) is of one of the most overlooked stages of the entire counselling process, but this is where the candidate&rsquo;s fate is decided. This is the stage where the candidate decides to express their interest in joining a set of medical colleges in the order of their preference. If they input these choices incorrectly, they risk ending up with no seat in hand.</p>
                <p className="para">We have a team of experts working behind the scenes, figuring out the most optimal choice entry strategies that will work for you. This involves a lot of mix &amp; match and using probability and analysis to boost the candidate&rsquo;s chances of securing a medical seat.</p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Notifications &amp; Updates </h4>
                <p className="para">Every year we closely interact with hundreds of NEET aspirants and notice that one of the most common issue plaguing this community is the lack of transparent information that will help these candidates have an easy time with the entire counselling process. Students are often confused and unaware of the counselling dates, the changing rules &amp; guidelines and the updates &amp; notifications published by the various counselling authorities. This information can help them be prepared for the admission process and make sure they don&rsquo;t miss any deadlines.</p>
                <p className="para">Over the past 6 years, thousands of NEET candidates have used our services and have found it to be their secret weapon at tackling the difficult NEET counselling process.</p>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}>
            <div className="row description_card">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Specialization Selection </h4>
                <p className="para">Are you a NEET PG aspirant trying to find out which specialization to apply for? Worry not, we have an expert panel of well-established doctors from around the world to help you figure it out.</p>
                <p className="para">Candidates can directly apply to interact with the medical professional of their choice and ask them anything about their chosen specialization - the good and the bad.</p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h4 className="header"> Mock Exams </h4>
                <p className="para">We have tied up with one of the most renowned online mock exam platforms in India - MegaExams. In association with MegaExams, we are providing our candidates with one of the most sought-after mock exams in the country.</p>
                <p className="para">This mock exam platform is powered by a self-learning AI that tracks a student&rsquo;s performance right from the get-go and helps them improve their scores by measuring their strengths &amp; weaknesses.</p>          
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}>
            <div className="row description_card">
              <div className="col">
                <h4 className="header"> FAQs for Notifications &amp; Updates </h4>
                <p className="para"><strong>Q: What sort of notifications will I receive?</strong></p>
                <p className="para">A: There are numerous counselling bodies in India that handle the NEET counselling process and all their rules, guidelines, deadliness, application procedure, documentation vary. You will receive information published by all these counselling authorities the moment they are released.</p><br/>
                <p className="para"><strong>Q: How will I receive this information?</strong></p>
                <p className="para">A: All this important information will be delivered straight to your mobile device, either via SMS and/or WhatsApp.</p><br/>
                <p className="para"><strong>Q: Can I get this information on my own?</strong></p>
                <p className="para">A: You would have to spend countless hours on the internet scouring through the websites of all the counselling authorities every few hours. So yes, it is possible, but that time could be spent elsewhere on more productive things.</p><br/>
                <p className="para"><strong>Q: Will I be reminded about deadlines?</strong></p>
                <p className="para">A: Each counselling authority will publish a list of dates, most candidates tend to miss these dates, for various reasons. We will send you reminders so you never forget a deadline again.</p>
              </div>
            </div>
          </ScrollAnimation>

          {/* get in touch container */}
          <ScrollAnimation animateIn="fadeIn" delay={100} duration={1} offset={50}>
            <div className="row interested_container">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 text-center p-4">
                <h2 className="main_header"><b> Interested? </b></h2>
                <h5 className="text-center mt-3"> Request a callback {is_auth ? '' : 'or Sign In' } to get in touch with us to know better about the colleges, study and work in abroad.</h5>
                <div className="mt-3">
                  {is_auth ? '' : <button className="mr-3 mt-2 abroad_btn py-2" onClick={this.handleLoginClick}> <i className="fa fa-sign-in mr-2"></i> SIGN IN / SIGN UP</button>}
                  <button className="mt-2 abroad_btn py-2" onClick={() => this.handleCallback('', '','','')}> <i className="fa fa-phone mr-2"></i> REQUEST CALLBACK</button>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          
        </div>
      </div>

    )
  }
}

const mapStatesToProps = (state) => {
  return {
    is_auth : state.home.is_auth
  }
}

const mapActionsToProps = {
  onSetCallbackValues : setCallbackValues
}

export default connect(mapStatesToProps, mapActionsToProps) (AboutUs);