import server from './Server';

export default function SetAuthToken (token)
{
    if(token)
    {
        server.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    else
    {
        delete server.defaults.headers.common['Authorization'];
    }
}