import server from "../utils/Server";
import { subLoaderTrue, subLoaderFalse } from "./Home";

export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COLLEGE_COUNTRIES = 'GET_COLLEGE_COUNTRIES';

export const getFilterCountries = (filters) => {
    return dispatch => {
        dispatch(subLoaderTrue());
        return server.get('/get_filter_countries' + filters)
            .then(response => {
                dispatch(subLoaderFalse());
                if(response.data.code === 200)
                {
                    dispatch(getFilterCountriesSuccess(response.data));
                }
            })
    }

}

export const getCollegeCountries = () => {
    return dispatch => {
        return server.get('/get_college_countries')
            .then(response => {
                if(response.data.code === 200)
                {
                    dispatch(getCollegeCountriesSuccess(response.data));
                }
            })
    }
}

export const getFilterCountriesSuccess = (data) => {
    return {
        type : GET_COUNTRIES_SUCCESS,
        payload : {
            countries : data.countries,
            page_size : data.page_size,
            page_count : data.page_count,
            total_count : data.total_count
        }
    }
}

export const getCollegeCountriesSuccess = (data) => {
    return {
        type : GET_COLLEGE_COUNTRIES,
        payload : {
            college_countries : data.countries
        }
    }
}