import React from 'react';
import './Register.css';
import { connect } from 'react-redux';
import { HashRouter as Router, Link } from 'react-router-dom';
import MaleDoctor from '../../assets/male_doctor.png';
import FemaleDoctor from '../../assets/female_doctor.png';
import JoinImage from '../../assets/join_us.png';
import swal from 'sweetalert';
import { registerUser } from '../../actions/Home';
import $ from 'jquery';

class Register extends React.Component {

	constructor (props) {
		super(props);
		this.state = { error : '' };
		this.handleLoginClick = this.handleLoginClick.bind(this);
	}

	componentWillMount () {
		if(this.props.is_auth)
		{
			window.location.hash = "#/";
		}
	}

	handleRegisterSubmit = (e) => {
		e.preventDefault();

		var pwd = document.getElementById('reg_pwd').value;
		var conf_pwd = document.getElementById('reg_cfrm_pwd').value;

		if(pwd.length >= 8 && pwd === conf_pwd)
		{
			this.setState({ error : '' });

			swal({
				
				title: "Are you sure?",
				text: "That all the information is correct and genuine.?",
				buttons: ['Cancel', 'Register'],
			})
			.then(willRegister => {
				if (willRegister) {
					var formData = new FormData(document.getElementById('register_form'));
					this.props.onRegisterUser(formData);
				}
			});

		}
		else
		{
			this.setState({ error : 'Both passwords should be same and have atleast 8 characters.' });
		}
	}

	handleLoginClick = (e) => {
		// Show login page
		$('.login_container').removeClass('d-none').removeClass('slideOutRight').addClass('slideInRight');
	}	

	render() {

		const { countries, streams, levels } = this.props;
		
		return (

			<div className="row register_container mt-4 pt-5">
				<div className="col">

					<h3 className="text-center"><b> REGISTER FOR FREE</b></h3>
										
					<div className="row mt-3">
								
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 register_details text-center">

							<img src={JoinImage} height="123" alt="Welcome" />
							<br/>
							<h2> W E L C O M E . . . </h2>
							<span> Your just need few seconds to register with us. </span>
							<br/>

							<div className="row d-none">
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 info_div">
									<h6> Interedted Country </h6>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 info_div">
									<h6> Interested Stream </h6>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 info_div">
									<h6> Interested Course level </h6>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 info_div">
									<h6> Interested Couser level </h6>
								</div>
							</div>

							<h4 className="mt-4"><b> Why to register? </b></h4>
							<div className="text-left">
								<span> <i className="fa fa-check-circle text-success"></i> Find the most optimal abroad college for you. </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Identify best college to study.</span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Search for the colleges you are interested across the world.  </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Get notifications based on your interested college, stream or course level. </span><br/>
								<span> <i className="fa fa-check-circle text-success"></i> Get 1-to-1 personalised guidance.</span><br/>
							</div>

							<div className="form-group text-center mt-4">
								<h5>Already registered?</h5>
								<button className="login_btn mt-2" title="Login" onClick={this.handleLoginClick}> LOGIN <i className="fa fa-sign-in ml-1"></i></button>
							</div>

						</div>
						<div className="col"></div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 register_details text-center p-2">

							<form id="register_form" onSubmit={this.handleRegisterSubmit} autoComplete="off">

								<div className="form-group">
									<label>
										<input type="radio" name="gender" className="form-control" defaultValue="Male" onChange={this.changeHandler} defaultChecked="true" required /> <img src={MaleDoctor} alt="Doctor" height="100"/>
									</label>
									<span className="mx-3">or</span>
									<label>
										<input type="radio" name="gender" className="form-control" defaultValue="Female" onChange={this.changeHandler} /> <img src={FemaleDoctor} alt="Doctor" height="100" required/>
									</label>
								</div>

								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-user"></i></span>
									</div>
									<input type="text" name="fname" className="form-control text-capitalize" placeholder="First name *" required/>
									<input type="text" name="lname" className="form-control text-capitalize" placeholder="Last name *" required/>
								</div>

								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-envelope"></i></span>
									</div>
									<input type="email" name="email" className="form-control" placeholder="Email ID *" required/>
								</div>
							
								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-phone"></i></span>
									</div>
									<input type="number" min="0" name="mobile" className="form-control" placeholder="Mobile Number *" required/>
								</div>

								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-user"></i></span>
									</div>
									<select name="country" className="form-control">
										<option value=""> Native country </option>
										{
											countries && countries.length > 0 ?
											countries.map((country) => {
												return <option key={country.id} value={country.id}>{country.con_name}</option>
											}) : ''
										}
									</select>

									<input type="text" name="city" className="form-control ml-2" placeholder="Enter your city" />
								</div>

								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-user"></i></span>
									</div>
									<select name="int_country" className="form-control">
										<option value=""> Interested country </option>
										{
											countries && countries.length > 0 ?
											countries.map((country) => {
												return <option key={country.id} value={country.id}>{country.con_name}</option>
											}) : ''
										}
									</select>
								</div>

								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-user"></i></span>
									</div>
									<select name="int_stream" className="form-control">
										<option value=""> Interested stream </option>
										{
											streams && streams.length > 0 ?
											streams.map((stream) => {
												return <option key={stream.id} value={stream.id}>{stream.str_name}</option>
											}) : ''
										}
									</select>

									<select name="int_course_level" className="form-control ml-2">
										<option value=""> Interested level </option>
										{
											levels && levels.length > 0 ?
											levels.map((level) => {
												return <option key={level.id} value={level.id}>{level.level_name}</option>
											}) : ''
										}
									</select>
								</div>

								<div className="form-group input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"> <i className="fa fa-lock"></i></span>
									</div>
									<input type="password" name="password" id="reg_pwd" className="form-control" placeholder="Password *" required/>
									<input type="password" name="password_confirmation" id="reg_cfrm_pwd" className="form-control" placeholder="Re-type Password *" required/>
								</div>

								<input type="checkbox" defaultChecked="true" /><span className="small_text ml-2 terms_check"> I agreed to all the <Router> <Link to="/terms_conditions"> Terms & conditions </Link> </Router> of GooCampus. </span>

								<span className="small_text"> {this.state.error} </span>

								<div className="form-group text-center mt-4">
									<button type="submit" className="login_btn"> REGISTER FOR FREE <i className="fa fa-user-plus ml-1"></i> </button>
								</div>

							</form>

						</div>
						<div className="col"></div>
					</div>
				</div>
			</div>
	
		);
	}
}

const mapStatesToProps = (state) => {
	return {
		main_loader : state.home.main_loader,
		is_auth : state.home.is_auth,
		countries : state.country.college_countries,
		streams : state.list.streams,
		levels : state.list.course_levels
	}
}

const mapActionsToProps = {
	onRegisterUser : registerUser
}

export default connect(mapStatesToProps, mapActionsToProps) (Register);