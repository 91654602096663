import server from '../utils/Server';
import { subLoaderTrue, subLoaderFalse, mainLoadingTrue, mainLoadingFalse, displayError } from './Home';

export const GET_COLLEGES_SUCCESS = 'GET_COLLEGES_SUCCESS';
export const GET_COLLEGE_SUCCESS = 'GET_COLLEGE_SUCCESS';
export const COLLEGE_COMPARE_SUCCESS = 'COLLEGE_COMPARE_SUCCESS';

export const getFilterColleges = (filters) => {
    return dispatch => {
        dispatch(subLoaderTrue());
        return server.get('/get_filter_colleges' + filters)
            .then(response => {
                dispatch(subLoaderFalse());
                if(response.data.code === 200)
                {
                    dispatch(getFilterCollegesSuccess(response.data));
                }
            })
    }
}

export const getCollegeDetails = (college) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.get('/get_college_details/' + college)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(getCollegeDetailsSuccess(response.data.college));
                }
            })
    }
}

export const compareColleges = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post('/compare_colleges', data)
            .then(response => {
                dispatch(mainLoadingFalse());
                if(response.data.code === 200)
                {
                    dispatch(collegeCompareSuccess(response.data.colleges));
                }
                else
                {
                    dispatch(displayError(response.data.error));
                }
            })
            .catch(err => {
                dispatch(displayError('Failed, try again later'));
            })
    }
}

export const getFilterCollegesSuccess = (data) => {
    return {
        type : GET_COLLEGES_SUCCESS,
        payload : {
            colleges : data.colleges,
            page_size : data.page_size,
            page_count : data.page_count,
            total_count : data.total_count,
            countries : data.countries
        }
    }
}

export const getCollegeDetailsSuccess = (college) => {
    return {
        type : GET_COLLEGE_SUCCESS,
        payload : {
            college : college
        }
    }
}

export const collegeCompareSuccess = (colleges) => {
    return {
        type : COLLEGE_COMPARE_SUCCESS,
        payload : {
            colleges : colleges
        }
    }
}